import { gql } from 'Operations/__generated__';

export const GET_PHOTOS = gql(/* GraphQL */ `
  query GetPhotos($where: PhotosGetWhereType!) {
    getPhotos(where: $where) {
      _count
      pageInfo {
        currentPage
        isFirstPage
        isLastPage
        pageCount
      }
      edges {
        ...PhotoCoreFields
      }
    }
  }
`);
