import React, { useCallback, useContext, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { App, Collapse, CollapseProps, Divider, Flex } from 'antd';
import { createStyles } from 'antd-style';

import { EditGallerySpotifyUriForm, EditGallerySpotifyUriFormPayload } from 'Forms/GallerySpotifyUri';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { UPDATE_GALLERY_SPOTIFY_URI } from 'Operations/Mutations/Gallery/UpdateGallerySpotifyUri';
import { UPDATE_GALLERY_PRESET_SPOTIFY_URI } from 'Operations/Mutations/GalleryPreset/UpdateGalleryPresetSpotifyUri';

interface Props {
  galleryId?: number;
  galleryPresetId?: number;
  spotifyUri?: string | null;
}

const useStyles = createStyles(({ css, token }) => ({
  tutorialList: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${token.sizeMD}px;
    margin-bottom: 0;
    font-size: ${token.sizeMS}px
    font-weight: bold;
  `,
  tutorialTitle: css`
    font-size: ${token.sizeMS}px;
    font-weight: bold;
    margin-bottom: 0;
  `,
  tutorialDescription: css`
    font-size: 14px;
    font-weight: normal;
    margin-top: ${token.sizeXXS}px;
    margin-bottom: 0;
  `,
  tutorialImg: css`
    width: 100%;
    height: auto;
    margin-top: ${token.sizeXXS}px;
  `,
}));

const ExtraSpotify = ({ galleryId, galleryPresetId, spotifyUri }: Props) => {
  const { t, locale } = useContext(LocalizationContext);
  const { message } = App.useApp();
  const { styles } = useStyles();

  const [updateSpotifyUri, { loading: isUpdateSpotifyUriLoading }] = useMutation(UPDATE_GALLERY_SPOTIFY_URI);
  const [updateGalleryPresetSpotifyUri, { loading: isUpdatePresetSpotifyUriLoading }] = useMutation(
    UPDATE_GALLERY_PRESET_SPOTIFY_URI,
  );

  const tutorialStep2Img = useMemo(() => {
    switch (locale) {
      case 'fr':
        return Images.spotifyTutorialStep2Fr;
      case 'de':
        return Images.spotifyTutorialStep2De;
      case 'nl':
        return Images.spotifyTutorialStep2Nl;
      case 'it':
        return Images.spotifyTutorialStep2It;
      case 'es':
        return Images.spotifyTutorialStep2Es;
      default:
        return Images.spotifyTutorialStep2En;
    }
  }, [locale]);

  const handleSubmit = useCallback(
    async ({ values: { spotifyCode, id, ...values }, formikBag }: EditGallerySpotifyUriFormPayload) => {
      try {
        await updateSpotifyUri({
          variables: { where: { id }, data: { spotifyUri: values.spotifyUri } },
          update(cache, result) {
            cache.modify({
              id: cache.identify({ id, __typename: result.data?.updateGallerySpotifyUri.__typename }),
              fields: {
                video() {
                  return values.spotifyUri || null;
                },
              },
            });
          },
        });

        if (!isUpdateSpotifyUriLoading) {
          formikBag.setSubmitting(false);
        }
        message.success(t('app.message.spotify.update.success'));
      } catch (error) {
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [isUpdateSpotifyUriLoading, t, updateSpotifyUri],
  );

  const handleGalleryPresetSubmit = useCallback(
    async ({ values: { spotifyCode, id, ...values }, formikBag }: EditGallerySpotifyUriFormPayload) => {
      try {
        await updateGalleryPresetSpotifyUri({
          variables: { where: { id }, data: { spotifyUri: values.spotifyUri } },
          update(cache, result) {
            cache.modify({
              id: cache.identify({ id, __typename: result.data?.updateGalleryPresetSpotifyUri.__typename }),
              fields: {
                video() {
                  return values.spotifyUri || null;
                },
              },
            });
          },
        });

        if (!isUpdatePresetSpotifyUriLoading) {
          formikBag.setSubmitting(false);
        }
        message.success(t('app.message.spotify.update.success'));
      } catch (error) {
        message.error(t('app.message.error.somethingWentWrong'));
      }
    },
    [isUpdatePresetSpotifyUriLoading, t, updateGalleryPresetSpotifyUri],
  );

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: t('app.gallery.spotify.tutorial.title'),
      children: (
        <ol className={styles.tutorialList}>
          <li>
            <p className={styles.tutorialTitle}>{t('app.gallery.spotify.tutorial.stepOne.title')}</p>
            <p className={styles.tutorialDescription}>{t('app.gallery.spotify.tutorial.stepOne.description')}</p>
          </li>
          <li>
            <p className={styles.tutorialTitle}>{t('app.gallery.spotify.tutorial.stepTwo.title')}</p>
            <p className={styles.tutorialDescription}>{t('app.gallery.spotify.tutorial.stepTwo.description')}</p>
            <img src={tutorialStep2Img} className={styles.tutorialImg} />
          </li>
          <li>
            <p className={styles.tutorialTitle}>{t('app.gallery.spotify.tutorial.stepThree.title')}</p>
            <p className={styles.tutorialDescription}>{t('app.gallery.spotify.tutorial.stepThree.description')}</p>
          </li>
        </ol>
      ),
    },
    {
      key: '2',
      label: t('app.gallery.spotify.subscription.title'),
      children: (
        <ul className={styles.tutorialList}>
          <li>
            <p className={styles.tutorialDescription}>{t('app.gallery.spotify.subscription.stepOne.description')}</p>
          </li>
          <li>
            <p className={styles.tutorialDescription}>{t('app.gallery.spotify.subscription.stepTwo.description')}</p>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <Flex vertical align="center" justify="center" gap="middle">
      {galleryId && (
        <EditGallerySpotifyUriForm
          onSubmit={handleSubmit}
          defaultValues={{
            spotifyCode: spotifyUri ? `https://open.spotify.com/embed/${spotifyUri.replace(/\:/g, '/')}` : null,
            spotifyUri,
            id: galleryId,
          }}
        />
      )}
      {galleryPresetId && (
        <EditGallerySpotifyUriForm
          onSubmit={handleGalleryPresetSubmit}
          defaultValues={{ spotifyUri, id: galleryPresetId }}
        />
      )}
      <Divider />
      <Collapse items={items} defaultActiveKey={!spotifyUri ? ['1'] : undefined} style={{ width: '100%' }} />
    </Flex>
  );
};

export default ExtraSpotify;
