import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { DeepPartial } from '@apollo/client/utilities';
import { Button, Flex, Switch as SwitchAntd, Tooltip } from 'antd';
import { createStyles, cx } from 'antd-style';
import { Color } from 'antd/es/color-picker';
import { ColorFactory } from 'antd/es/color-picker/color';
import { Link } from 'react-router-dom';

import Frame from 'react-frame-component';
import ReactMarkdown from 'react-markdown';

import { Cover, CoverDisplayType, RenderCountdownCallback } from '@fotostudio/components';
import { ImageFocalPoint } from '@lemoncode/react-image-focal-point';
import '@lemoncode/react-image-focal-point/style.css';
import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, FormItem, Slider, Switch } from 'formik-antd';
import { camelCase, compact, isEqual, uniq, uniqBy } from 'lodash';

import Fieldset from 'Components/Atoms/Fieldset';
import Legend from 'Components/Atoms/Legend';
import Text from 'Components/Atoms/Text';

import ColorPicker from 'Components/Molecules/Form/ColorPicker';
import DrawerPicker from 'Components/Molecules/Form/DrawerPicker';
import Select from 'Components/Molecules/Form/Select';

import { Images } from 'Themes';
import { FONTS } from 'Themes/Fonts';

import i18n, { LocalizationContext } from 'i18n';

import useSize from 'Hooks/UseSize';

import dayjs from 'Services/DayjsService';
import yup from 'Services/YupService';

import {
  Align,
  Coordinate,
  CoverType,
  FontStyle,
  FontWeight,
  GalleryAppearenceLogo,
  GalleryField,
  GalleryOrderType,
  GalleryTheme,
  PhotosSortField,
  PhotosSortOrder,
  Position,
  ShootedByType,
} from 'Operations/__generated__/graphql';

import { GET_GALLERIES_NAME } from 'Operations/Queries/Gallery/GetGalleriesNames';
import { GET_GALLERY_APPEARANCE } from 'Operations/Queries/Gallery/GetGalleryAppearance';
import { GET_GALLERY_PHOTOS } from 'Operations/Queries/Gallery/GetGalleryPhotos';
import { GET_PHOTO } from 'Operations/Queries/Photo/GetPhoto';

export interface EditGalleryCoverFormValues {
  id: number;
  coverType: CoverType;
  coverPhotoId?: number | null;
  backgroundColor: Color;
  textColor: Color;
  font: string;
  titleFontSize: number;
  isLogoActivated?: boolean;
  coverInfoPosition: Position;
  withShootedBy: boolean;
  shootedByType: ShootedByType;
  shootedByAlign: Align;
  withOverlay: boolean;
  overlayAlpha: number;
  isTitleFontBold: boolean;
  isTitleFontItalic: boolean;
  isCoverWatermarked: boolean;
  isPreset?: boolean;
  isFullImage?: boolean;
  focalPoint?: Coordinate;
}

const PER_PAGE = 50;

const useStyles = createStyles(({ css, token, responsive }) => ({
  container: css`
    ${responsive.md} {
      flex-direction: column;
    }
    width: 100%;
  `,
  itemContainer: css`
    flex-direction: column;
    ${responsive.md} {
      flex-direction: row;
      gap: ${token.sizeSM}px;
    }
  `,
  formContainer: css`
    min-width: 225px;
    width: 225px;
    flex: 0;
    ${responsive.md} {
      display: flex;
      flex-direction: row;
      gap: ${token.size}px;
      width: 100%;

      .Fieldset {
        flex: 1;
      }
    }
  `,
  reverseContainer: css`
    ${responsive.md} {
      flex-direction: row-reverse;
    }
  `,
  text: css`
    margin: ${token.sizeSM}px 0;
  `,
  fieldset: css`
    flex: 1;
  `,
  formItem: css`
    max-width: 180px;
  `,
  content: css`
    width: 100%;
    text-align: left;
  `,
  coverContainer: css`
    transition: width 0.3s, height 0.3s;

    margin: 0 auto;
    align-self: center;

    border: 0;

    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);

    &.mobile-cover {
      width: 390px;
    }

    &.desktop-cover {
      width: 100%;
    }
  `,
  previewCoverImage: css`
    object-fit: contain;
    width: 100%;
    height: auto;
  `,
  previewCoverImageRoot: css`
    height: auto !important;
    width: auto !important;
  `,
  hiddenFocalPoint: css`
    display: none !important;
  `,
}));

const GALLERIES_PARAMS = {
  perPage: PER_PAGE,
  order: GalleryOrderType.ASC,
  field: GalleryField.NAME,
  delete: false,
};

const getColor = (color: string) => `rgb(${color})`;

const GalleryCoverForm = <T extends EditGalleryCoverFormValues & { galleryCoverId?: number }>({
  isSubmitting,
  values,
  setFieldValue,
  setValues,
  galleryId,
  name,
  shootedAt,
  availableAt,
  coverType,
  onChangeCoverType,
  shootedByName,
  logoUrl,
  initialValues,
  theme,
}: FormikProps<T> & EditGalleryCoverFormProps) => {
  const { t } = useContext(LocalizationContext);
  const { styles, cx } = useStyles();

  const [isMobileDisplay, setIsMobileDisplay] = useState(false);
  const [shouldAskAccessCode, setShouldAskAccessCode] = useState(false);
  const [shouldDisplayCountdown, setShouldDisplayCountdown] = useState(false);

  const [initialIframeContent, setInitialIframeContent] = useState('');

  useLayoutEffect(() => {
    setInitialIframeContent(
      `<!DOCTYPE html><html><head>${document.head.innerHTML.toString()}</head><body><div></div></body></html>`,
    );
  }, []);

  const gallerySearchTerms = useRef<string | undefined>(undefined);
  const previewRef = useRef<HTMLDivElement>(null);

  const previewSize = useSize(previewRef);
  const previewHeight = useMemo(
    () => (previewSize?.width ? Math.max(previewSize.width / 1.77777778, 650) : 650),
    [previewSize?.width],
  );

  const listParams = useMemo(
    () => ({
      sortField: PhotosSortField.NAME,
      sortOrder: PhotosSortOrder.ASC,
      filter: '',
      perPage: PER_PAGE,
    }),
    [],
  );

  const colorPreset = useMemo(
    () =>
      theme
        ? [
            {
              label: t('app.gallery.tabs.theme', { count: 1 }),
              colors: uniq([
                getColor(theme.primaryColor || ''),
                getColor(theme.secondaryColor || ''),
                getColor(theme.backgroundColor || ''),
                getColor(theme.menuBackgroundColor || ''),
                getColor(theme.paymentBackgroundColor || ''),
              ]),
              defaultOpen: true,
            },
          ]
        : undefined,
    [theme, t],
  );

  const {
    data,
    fetchMore,
    loading: isLoadingCoverPhotos,
  } = useQuery(GET_GALLERY_PHOTOS, {
    skip: !galleryId,
    variables: {
      galleryId: galleryId as number,
      where: listParams,
    },
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  });

  const { data: photoData } = useQuery(GET_PHOTO, {
    skip: !galleryId || !values.coverPhotoId,
    variables: {
      where: {
        galleryId: galleryId as number,
        id: values.coverPhotoId as number,
      },
    },
    fetchPolicy: 'network-only',
  });

  const {
    data: galleriesData,
    loading: isGalleriesLoading,
    fetchMore: fetchMoreGalleries,
    refetch: refetchGalleries,
  } = useQuery(GET_GALLERIES_NAME, {
    skip: !galleryId,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: {
        page: 1,
        ...GALLERIES_PARAMS,
      },
    },
  });

  const [getGalleryAppearance, { data: appearanceData }] = useLazyQuery(GET_GALLERY_APPEARANCE);

  const gallery = useMemo(
    () => (data?.getGallery?.__typename === 'GalleryAdmin' ? data.getGallery : undefined),
    [data],
  );

  const photos = useMemo(
    () =>
      gallery?.photos?.edges?.filter(photo => photo.__typename === 'PhotoAdmin' && !!photo.asset?.noWmThumbSmall) || [],
    [gallery?.photos],
  );
  const coverPhoto = useMemo(
    () => (photoData?.getPhoto.__typename === 'PhotoAdmin' ? photoData.getPhoto : undefined),
    [photoData],
  );

  const coverImage = useMemo(() => {
    if (values.isCoverWatermarked) {
      return {
        url: coverPhoto?.asset?.assetWeb?.downloadUrl || undefined,
        loader: coverPhoto?.asset?.thumbSmall?.downloadUrl,
      };
    } else {
      return {
        url: coverPhoto?.asset?.noWmAssetWeb?.downloadUrl || undefined,
        loader: coverPhoto?.asset?.noWmThumbSmall?.downloadUrl,
      };
    }
  }, [coverPhoto, values.isCoverWatermarked]);

  const photosPage = useMemo(() => {
    if (!photos?.length) {
      return 1;
    }
    const loadedCount = photos.length;

    const currentPage = Math.ceil(loadedCount / PER_PAGE);
    return loadedCount % PER_PAGE === 0 ? currentPage : currentPage - 1;
  }, [photos.length]);

  const hasFetchAllPhotos = useMemo(
    () => !!gallery?.photos && gallery?.photos?.edges?.length === gallery?.photos?._count,
    [gallery?.photos],
  );

  const galleryPage = useMemo(() => {
    if (!galleriesData?.getGalleries?.edges.length) {
      return 1;
    }
    const loadedCount = galleriesData?.getGalleries?.edges.length;

    const currentPage = Math.ceil(loadedCount / PER_PAGE);
    return loadedCount % PER_PAGE === 0 ? currentPage : currentPage - 1;
  }, [galleriesData?.getGalleries?.edges.length]);

  const hasFetchAllGalleries = useMemo(
    () => galleriesData?.getGalleries?.edges?.length === galleriesData?.getGalleries?._count,
    [galleriesData],
  );

  const galleries = useMemo(() => {
    if (galleriesData && galleryId) {
      const filteredGalleries = galleriesData.getGalleries.edges.filter(gallery => gallery.id !== galleryId);

      return uniqBy(filteredGalleries, 'id');
    }

    return [];
  }, [galleriesData, galleryId]);

  useEffect(() => {
    if (!values.shootedByType) {
      if (coverType === CoverType.DELTA) {
        setFieldValue('shootedByType', ShootedByType.HORIZONTAL_BOTTOM);
      } else {
        setFieldValue('shootedByType', ShootedByType.VERTICAL_LEFT);
      }
    }
    setFieldValue('coverType', coverType);
  }, [coverType, setFieldValue, values.shootedByType]);

  useEffect(() => {
    if (values.galleryCoverId) {
      getGalleryAppearance({
        variables: {
          where: {
            id: values.galleryCoverId,
          },
        },
      });
    } else {
      gallerySearchTerms.current = undefined;
      onChangeCoverType(initialValues.coverType);
      setValues({
        ...initialValues,
      });
    }
  }, [getGalleryAppearance, initialValues, onChangeCoverType, setValues, values.galleryCoverId]);

  useEffect(() => {
    if (appearanceData && appearanceData?.getGallery?.__typename === 'GalleryAdmin') {
      const { galleryAppearance } = appearanceData.getGallery;

      if (!galleryAppearance) {
        return;
      }

      onChangeCoverType(galleryAppearance.coverType);

      setValues(oldValues => ({
        ...oldValues,
        backgroundColor: new ColorFactory(`rgb(${galleryAppearance.backgroundColor || '255,255,255'})`),
        textColor: new ColorFactory(`rgb(${galleryAppearance.textColor || '255,255,255'})`),
        font: galleryAppearance.font,
        coverInfoPosition: galleryAppearance.coverInfoPosition,
        withShootedBy: galleryAppearance.withShootedBy,
        shootedByType: galleryAppearance.shootedByType,
        shootedByAlign: galleryAppearance.shootedByAlign,
        withOverlay: galleryAppearance.withOverlay,
        overlayAlpha: galleryAppearance.overlayAlpha,
        isTitleFontBold: galleryAppearance.titleFontWeight === FontWeight.BOLD,
        isTitleFontItalic: galleryAppearance.titleFontStyle === FontStyle.ITALIC,
      }));
    }
  }, [appearanceData, onChangeCoverType, setValues]);

  const getMorePhotos = useCallback(async () => {
    if (!hasFetchAllPhotos && galleryId) {
      await fetchMore({
        variables: {
          galleryId,
          where: { page: photosPage + 1, ...listParams },
        },
      });
    }
  }, [fetchMore, galleryId, hasFetchAllPhotos, listParams, photosPage]);

  const getMoreGalleries = useCallback(() => {
    fetchMoreGalleries({
      variables: {
        where: {
          ...GALLERIES_PARAMS,
          page: galleryPage + 1,
          search: gallerySearchTerms.current,
        },
      },
    });
  }, [fetchMoreGalleries, galleryPage]);

  const handleSearchGalleries = useCallback(
    async (searchTerms: string) => {
      gallerySearchTerms.current = searchTerms.length > 0 ? searchTerms : undefined;
      return refetchGalleries({
        where: {
          ...GALLERIES_PARAMS,
          page: 1,
          search: gallerySearchTerms.current,
        },
      });
    },
    [refetchGalleries],
  );

  const renderPositionOptions = () => {
    const positionValues = [];

    for (const position in Position) {
      positionValues.push(
        <Select.Option key={position} value={position} title={''}>
          {t(`app.watermark.position.${camelCase(position.toLowerCase())}`)}
        </Select.Option>,
      );
    }

    return positionValues;
  };

  const renderShootedByTypeOptions = () => {
    const shootedByTypeValues = [];

    for (const sbt in ShootedByType) {
      if (coverType !== CoverType.GAMMA || (coverType === CoverType.GAMMA && sbt !== ShootedByType.HORIZONTAL_TOP)) {
        shootedByTypeValues.push(
          <Select.Option key={sbt} value={sbt} title={''}>
            {t(`app.gallery.cover.shootedByType.${camelCase(sbt.toLowerCase())}`)}
          </Select.Option>,
        );
      }
    }

    return shootedByTypeValues;
  };

  const renderShootedByAlignOptions = ({ shootedByType }: { shootedByType: ShootedByType }) => {
    const shootedByAlignValues = [];

    for (const align in Align) {
      let label = camelCase(align.toLowerCase());

      if (shootedByType === ShootedByType.VERTICAL_LEFT || shootedByType === ShootedByType.VERTICAL_RIGHT) {
        if (align === Align.LEFT) {
          label = 'bottom';
        } else {
          label = 'top';
        }
      }

      shootedByAlignValues.push(
        <Select.Option key={align} value={align} title={''}>
          {t(`app.gallery.cover.shootedByAlign.${label}`)}
        </Select.Option>,
      );
    }

    return shootedByAlignValues;
  };

  const handleRenderCountdown: RenderCountdownCallback = useCallback(
    ({ days, hours, minutes, seconds }) =>
      `${days} ${t('app.common.day', { count: days })} ${hours} ${t('app.common.hour', {
        count: hours,
      })} ${minutes} ${t('app.common.minute', { count: minutes })} ${seconds} ${t('app.common.second', {
        count: seconds,
      })}`,
    [t],
  );

  const coverDisplay: CoverDisplayType = useMemo(() => {
    if (shouldAskAccessCode) {
      return CoverDisplayType.ACCESS_CODE;
    }
    if (shouldDisplayCountdown) {
      return CoverDisplayType.COUNTDOWN;
    }
    return CoverDisplayType.COVER_TITLE;
  }, [shouldAskAccessCode, shouldDisplayCountdown]);

  return (
    <Form layout="vertical">
      <Flex gap="middle" className={styles.container}>
        <div className={styles.formContainer}>
          <div>
            {!values.isPreset && (
              <Form.Item label={t('app.gallery.cover.import')} name="galleryCoverId" hasFeedback={false}>
                <Select
                  name="galleryCoverId"
                  size="large"
                  showSearch
                  onSearch={handleSearchGalleries}
                  allowClear
                  optionFilterProp="children"
                  loadMore={getMoreGalleries}
                  hasFetchAll={hasFetchAllGalleries}
                  loading={isGalleriesLoading}
                  getPopupContainer={trigger => trigger.parentNode}
                >
                  {galleries.map(gallery => (
                    <Select.Option key={gallery.id} value={gallery.id} title={''}>
                      {gallery.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            {!values.isPreset && (
              <Fieldset className={cx('Fieldset', styles.fieldset)}>
                <Legend>{t('app.gallery.cover.photo')}</Legend>
                <Flex justify="start" className={cx(styles.itemContainer, styles.reverseContainer)}>
                  <Form.Item
                    label={t('app.common.photo', { count: 1 })}
                    name="coverPhotoId"
                    required
                    hasFeedback={false}
                  >
                    <DrawerPicker
                      name="coverPhotoId"
                      actionLabel={t('app.gallery.cover.select')}
                      dataSource={compact(
                        photos.map(photo =>
                          photo.__typename === 'PhotoAdmin'
                            ? {
                                id: photo.id,
                                url: photo?.asset?.noWmThumbSmall?.downloadUrl || '',
                              }
                            : undefined,
                        ),
                      )}
                      hasFetchAll={hasFetchAllPhotos}
                      loadMore={getMorePhotos}
                      isLoading={isLoadingCoverPhotos}
                    />
                  </Form.Item>
                  {coverImage.url && (
                    <FormItem
                      label={t('app.gallery.cover.focal')}
                      name="focalPoint"
                      style={{ display: 'flex', justifyContent: 'flex-start' }}
                    >
                      <ImageFocalPoint
                        src={coverImage.url || Images.coverMissing}
                        classes={{
                          image: styles.previewCoverImage,
                          root: styles.previewCoverImageRoot,
                          focalPoint: values.isFullImage ? styles.hiddenFocalPoint : '',
                        }}
                        onChange={focalPoint => setFieldValue('focalPoint', focalPoint)}
                        focalPoint={values.focalPoint}
                      />
                    </FormItem>
                  )}
                </Flex>
                <Flex className={styles.itemContainer}>
                  <Form.Item
                    label={t('app.gallery.cover.isFullCover.label')}
                    help={t(`app.gallery.cover.isFullCover.${values.isFullImage ? 'enabled' : 'disabled'}`)}
                    name="isFullImage"
                    hasFeedback={false}
                  >
                    <Switch name="isFullImage" />
                  </Form.Item>
                  {!!coverPhoto?.isWatermarked ? (
                    <Form.Item label={t('app.gallery.cover.watermark')} name="isCoverWatermarked" hasFeedback={false}>
                      <Switch name="isCoverWatermarked" />
                    </Form.Item>
                  ) : (
                    <div style={{ flex: 1 }} />
                  )}
                </Flex>
              </Fieldset>
            )}
            <Fieldset className={cx('Fieldset', styles.fieldset)}>
              <Legend>{t('app.common.title')}</Legend>
              <Form.Item label={t('app.gallery.cover.logo')} name="isLogoActivated" hasFeedback={false}>
                <Switch name="isLogoActivated" />
              </Form.Item>
              {coverType !== CoverType.ALPHA &&
                coverType !== CoverType.GAMMA &&
                coverType !== CoverType.DELTA &&
                coverType !== CoverType.EPSILON && (
                  <Form.Item
                    label={t('app.gallery.cover.coverInfoPosition')}
                    name="coverInfoPosition"
                    required
                    hasFeedback={false}
                  >
                    <Select name="coverInfoPosition" size="large" showSearch optionFilterProp="children">
                      {renderPositionOptions()}
                    </Select>
                  </Form.Item>
                )}
              <Form.Item label={t('app.watermark.font.label')} name="font" required hasFeedback={false}>
                <Select name="font" size="large">
                  {FONTS.map(font => (
                    <Select.Option value={font} title={''}>
                      {font} - <span style={{ fontFamily: font }}>{name}</span>
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Fieldset className={cx('Fieldset', styles.fieldset)}>
                <Legend>{t('app.common.style')}</Legend>
                <Flex>
                  <Form.Item label={t('app.font.weight.bold')} name="isTitleFontBold" hasFeedback={false}>
                    <Switch name="isTitleFontBold" />
                  </Form.Item>
                  <Form.Item label={t('app.font.style.italic')} name="isTitleFontItalic" hasFeedback={false}>
                    <Switch name="isTitleFontItalic" />
                  </Form.Item>
                </Flex>
                <Form.Item label={t('app.common.size')} name="titleFontSize" hasFeedback={false}>
                  <Slider name="titleFontSize" min={12} max={50} step={1} />
                </Form.Item>
              </Fieldset>
            </Fieldset>
            {(coverType === CoverType.BETA || coverType === CoverType.ZETA) && (
              <Fieldset className={cx('Fieldset', !values.withOverlay && 'Fieldset--NoContent', styles.fieldset)}>
                <Legend>
                  <FormItem
                    name="withOverlay"
                    label={t('app.gallery.cover.withOverlay')}
                    className={cx('ant-form-item--switch', styles.formItem)}
                  >
                    <Switch name="withOverlay" />
                  </FormItem>
                </Legend>
                {values.withOverlay && (
                  <Form.Item name="overlayAlpha" required hasFeedback={false}>
                    <Slider name="overlayAlpha" min={0.1} max={1} step={0.1} />
                  </Form.Item>
                )}
              </Fieldset>
            )}
            <Fieldset className={cx('Fieldset', styles.fieldset)}>
              <Legend>{t('app.common.color', { count: 2 })}</Legend>
              <Flex className={styles.itemContainer}>
                <Form.Item
                  label={t('app.gallery.cover.backgroundColor')}
                  name="backgroundColor"
                  required
                  hasFeedback={false}
                >
                  <ColorPicker
                    name="backgroundColor"
                    size="large"
                    defaultFormat="hex"
                    disabledAlpha
                    showText
                    presets={colorPreset}
                  />
                </Form.Item>
                <Form.Item label={t('app.gallery.cover.textColor')} name="textColor" required hasFeedback={false}>
                  <ColorPicker
                    name="textColor"
                    size="large"
                    defaultFormat="hex"
                    disabledAlpha
                    showText
                    presets={colorPreset}
                  />
                </Form.Item>
              </Flex>
            </Fieldset>
            <Fieldset className={cx('Fieldset', !values.withShootedBy && 'Fieldset--NoContent', styles.fieldset)}>
              <Legend>
                <FormItem
                  name="withShootedBy"
                  label={t('app.gallery.cover.shootedBy')}
                  className={cx('ant-form-item--switch', styles.formItem)}
                >
                  <Switch name="withShootedBy" />
                </FormItem>
              </Legend>
              {values.withShootedBy && (
                <Flex className={styles.itemContainer}>
                  <Form.Item name="shootedByType" required hasFeedback={false}>
                    <Select name="shootedByType" size="large">
                      {renderShootedByTypeOptions()}
                    </Select>
                  </Form.Item>
                  <Form.Item name="shootedByAlign" required hasFeedback={false}>
                    <Select name="shootedByAlign" size="large">
                      {renderShootedByAlignOptions({ shootedByType: values.shootedByType })}
                    </Select>
                  </Form.Item>
                </Flex>
              )}
            </Fieldset>
          </div>
        </div>
        <Flex vertical gap="small" flex={1} style={{ zIndex: 0 }}>
          <ReactMarkdown
            children={t('app.gallery.cover.companySettingsDescription', {
              link: '/app/settings/my-activity',
            })}
            components={{
              p: ({ children }) => <Text className={styles.text}>{children}</Text>,
              a: ({ children, ...props }) => <Link to={props.href || '.'}>{children}</Link>,
            }}
          />
          <Flex vertical gap="middle" ref={previewRef} style={{ position: 'sticky', top: 0, paddingTop: 16 }}>
            <Flex justify="flex-end" align="center" gap="small">
              <Button
                htmlType="submit"
                type="primary"
                size="large"
                loading={isSubmitting}
                disabled={isEqual(initialValues, values)}
              >
                {t('app.gallery.cover.update')}
              </Button>
            </Flex>
            <Flex justify="space-around" gap="middle">
              <Flex vertical align="center">
                {t('app.gallery.cover.preview.countdown')}
                <SwitchAntd
                  style={{ display: 'block' }}
                  onChange={() => {
                    setShouldDisplayCountdown(!shouldDisplayCountdown);
                    setShouldAskAccessCode(false);
                  }}
                  value={shouldDisplayCountdown}
                />
              </Flex>
              <Flex vertical align="center">
                {t('app.gallery.cover.preview.accessCode')}
                <SwitchAntd
                  style={{ display: 'block' }}
                  onChange={() => {
                    setShouldAskAccessCode(!shouldAskAccessCode);
                    setShouldDisplayCountdown(false);
                  }}
                  value={shouldAskAccessCode}
                />
              </Flex>
              <Flex vertical align="center">
                {t('app.gallery.cover.preview.mobile')}
                <SwitchAntd style={{ display: 'block' }} onChange={() => setIsMobileDisplay(!isMobileDisplay)} />
              </Flex>
            </Flex>
            <Frame
              className={cx(
                styles.coverContainer,
                isMobileDisplay && 'mobile-cover',
                !isMobileDisplay && 'desktop-cover',
              )}
              initialContent={initialIframeContent}
              style={{ height: previewHeight }}
            >
              <Cover
                displayType={coverDisplay}
                appearence={{
                  backgroundColor: values.backgroundColor.toHexString(),
                  coverInfoPosition: values.coverInfoPosition,
                  coverType,
                  coverUrl: values.isPreset ? Images.coverPreview1 : coverImage.url,
                  font: values.font,
                  logoType: GalleryAppearenceLogo.DEFAULT,
                  overlayAlpha: values.overlayAlpha,
                  shootedByAlign: values.shootedByAlign,
                  shootedByType: values.shootedByType,
                  textColor: values.textColor.toHexString(),
                  titleFontStyle: values.isTitleFontItalic ? FontStyle.ITALIC : FontStyle.NORMAL,
                  titleFontWeight: values.isTitleFontBold ? FontWeight.BOLD : FontWeight.NORMAL,
                  titleFontSize: values.titleFontSize,
                  withOverlay:
                    values?.coverType &&
                    values?.withOverlay &&
                    (values?.coverType === CoverType.BETA || values?.coverType === CoverType.ZETA),
                  withShootedBy: values.withShootedBy,
                  company:
                    values.isLogoActivated && logoUrl
                      ? {
                          logoUrl,
                        }
                      : undefined,
                  coverUrlLoader: coverImage.loader,
                  isFullImage: values.isFullImage,
                  focalPoint: values.focalPoint,
                }}
                theme={{
                  primaryColor: values?.textColor.toRgbString() || '#fff',
                  secondaryColor: values?.backgroundColor.toRgbString() || '#000',
                  buttonFont: theme?.buttonFont || 'Open Sans',
                  buttonRadius: theme?.buttonRadius || 0,
                }}
                data={{
                  name,
                  shootedByName,
                  shootedAt: shootedAt ? dayjs(shootedAt).format('LL') : undefined,
                  availableAt: availableAt || dayjs().add(3, 'days').toISOString(),
                  coverMissingUrl:
                    values.coverType === CoverType.ALPHA ? Images.coverPortraitMissing : Images.coverMissing,
                }}
                locales={{
                  countdownTitle: t('app.gallery.cover.countdown.title'),
                  accessCodeTitle: t('app.gallery.cover.accessCode.title'),
                  accessCodePlaceholder: t('app.gallery.cover.accessCode.placeholder'),
                  accessCodeSubmit: t('app.gallery.cover.accessCode.submit'),
                  accessCodeForget: t('app.gallery.cover.accessCode.forget'),
                }}
                renderCountdown={handleRenderCountdown}
                onAccessCodeForget={() => null}
              />
            </Frame>
          </Flex>
        </Flex>
      </Flex>
    </Form>
  );
};

export interface EditGalleryCoverPayload {
  values: EditGalleryCoverFormValues;
  formikBag: FormikBag<EditGalleryCoverFormProps, EditGalleryCoverFormValues>;
}

export interface EditGalleryCoverFormProps {
  onSubmit: (payload: EditGalleryCoverPayload) => void;
  galleryId?: number;
  coverType: CoverType;
  onChangeCoverType: (coverType: CoverType) => void;
  name: string;
  availableAt?: string;
  shootedAt?: string;
  shootedByName?: string | null;
  logoUrl?: string | null;
  defaultValues: EditGalleryCoverFormValues;
  isPreset?: boolean;
  theme?: DeepPartial<GalleryTheme>;
}

const galleryCoverSchema = yup.object({
  id: yup.number().required(),
  coverType: yup.mixed<CoverType>().required(),
  coverPhotoId: yup.number().when('isPreset', {
    is: false,
    then: yup.number().nullable().required(i18n.t('app.form.errors.coverMissing')),
    otherwise: yup.number().nullable(),
  }),
  backgroundColor: yup.object<any>().required(),
  textColor: yup.object<any>().required(),
  focalPoint: yup.mixed<Coordinate>().required(),
  titleFontSize: yup.number().required(),
  font: yup.string().trim().required(),
  coverInfoPosition: yup.mixed<Position>().required(),
  isTitleFontBold: yup.boolean().required(),
  isTitleFontItalic: yup.boolean().required(),
  withShootedBy: yup.boolean().required(),
  shootedByType: yup.mixed<ShootedByType>().required(),
  shootedByAlign: yup.mixed<Align>().required(),
  withOverlay: yup.boolean().required(),
  overlayAlpha: yup.number().required(),
  isLogoActivated: yup.boolean().required(),
  isCoverWatermarked: yup.boolean().required(),
  isPreset: yup.boolean(),
  isFullImage: yup.boolean(),
});

const editGalleryCoverSchema: yup.SchemaOf<EditGalleryCoverFormValues> = galleryCoverSchema.defined();

export const EditGalleryCoverForm = withFormik<EditGalleryCoverFormProps, EditGalleryCoverFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  enableReinitialize: true,
  mapPropsToValues: ({ defaultValues }) => defaultValues,
  validationSchema: editGalleryCoverSchema,
})(GalleryCoverForm);
