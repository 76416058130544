import { gql } from 'Operations/__generated__';

export const GET_CONTACT_AND_ACCESS_CODE = gql(/* GraphQL */ `
  query GetContactAndAccessCode($where: ContactGetWhereType!, $accessCodeWhere: GalleryIdInput!) {
    getContactAndAccessCode: getContact(where: $where) {
      ...ContactCoreFields
      accessCode(where: $accessCodeWhere) {
        ...AccessCodeCoreFields
      }
      vat
      phone
      birthdate
      birthdayEmail
      favorite
      createdAt
      street
      city
      zipcode
      stateId
      countryId
      prospectOriginId
      graphicalIdentityId
      transportFee
      tags
      photoUrl
      isZoneEnabled
      taxCode
    }
  }
`);
