import React, { useCallback, useContext, useMemo, useState } from 'react';

import { DeepPartial } from '@apollo/client/utilities';
import { Alert, Flex, Menu } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';

import AccessItem from 'Components/Molecules/AccessItem';

import { AccessType } from 'Forms/Access';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { Contact, GalleryAccessPolicy } from 'Operations/__generated__/graphql';

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
  margin-top: 4px;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  border-radius: 16px;
`;

const StyledSidebar = styled.div`
  width: 200px;
  display: block;
  border-right: 1px solid ${Colors.border};
`;

interface GalleryAccessTabProps {
  galleryId: number;
  galleryUrl: string;
  galleryContact: DeepPartial<Contact> | null;
  isClientCodesAdminEnabled: boolean;
  accessPolicy: GalleryAccessPolicy;
  isEmailRequired: boolean;
}

const GalleryAccessTab = ({
  isClientCodesAdminEnabled,
  galleryUrl,
  accessPolicy,
  galleryContact,
  galleryId,
  isEmailRequired,
}: GalleryAccessTabProps) => {
  const { t } = useContext(LocalizationContext);
  const [currentAccessType, setCurrentAccessType] = useState<AccessType>('client');

  const accessButtons = useMemo(() => {
    const accessButtons: MenuItemType[] = [
      {
        label: t('app.gallery.access.sidebar.customer'),
        key: 'client',
      },
    ];

    if (
      accessPolicy === GalleryAccessPolicy.ACCESS_CODE ||
      (accessPolicy === GalleryAccessPolicy.PUBLIC && isEmailRequired)
    ) {
      accessButtons.push({
        label: t('app.gallery.access.sidebar.group'),
        key: 'group',
      });
    }

    accessButtons.push({
      label: t('app.gallery.access.sidebar.visitor'),
      key: 'visitor',
    });

    return accessButtons;
  }, [accessPolicy]);

  const handleChange = useCallback(
    (accessType: AccessType) => {
      setCurrentAccessType(accessType);
    },
    [currentAccessType],
  );

  return (
    <>
      {accessPolicy === 'PUBLIC' && (
        <StyledAlert message={t('app.gallery.access.accessPolicy.alert.isPublic')} type="info" />
      )}
      <Flex align="stretch">
        <StyledSidebar>
          <Menu
            defaultSelectedKeys={['client']}
            items={accessButtons}
            onSelect={selectedKeys => handleChange(selectedKeys.key as AccessType)}
          />
        </StyledSidebar>
        <Flex vertical flex={1}>
          <AccessItem
            isClientCodesAdminEnabled={isClientCodesAdminEnabled}
            key={currentAccessType}
            galleryUrl={galleryUrl}
            type={currentAccessType}
            galleryId={galleryId}
            galleryContactId={galleryContact?.id}
            galleryAccessPolicy={accessPolicy}
          />
        </Flex>
      </Flex>
    </>
  );
};

export default GalleryAccessTab;
