import { gql } from 'Operations/__generated__';

export const GET_CLIENTS = gql(/* GraphQL */ `
  query GetClients($where: GalleryIdInput!, $paginate: PaginatedInput) {
    getClients(where: $where, paginate: $paginate) {
      _count
      edges {
        ...ContactCoreFields
        accessCode(where: $where) {
          ...AccessCodeCoreFields
        }
      }
      pageInfo {
        currentPage
        isFirstPage
        isLastPage
        pageCount
      }
    }
  }
`);
