import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Flex } from 'antd';

import { useFormikContext } from 'formik';
import { DatePicker, Form, Input, Switch } from 'formik-antd';
import { compact, flatten, kebabCase, uniqBy } from 'lodash';

import Icon from 'Components/Atoms/Icon';

import Select from 'Components/Molecules/Form/Select';
import SelectContact from 'Components/Molecules/Inputs/SelectContact';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';

import generateId from 'Helpers/GenerateId';

import { GalleryAccessPolicy, GalleryStatus, Locale } from 'Operations/__generated__/graphql';

import { GET_GALLERY_CONTACTS } from 'Operations/Queries/Gallery/GetGalleryContacts';
import { GET_PROJECTS } from 'Operations/Queries/Project/GetProjects';
import { ME } from 'Operations/Queries/User/Me';

import { DataQueryParams, EDGES_PARAMS, GalleryFormValues } from './types';

const GalleryInfoStep = () => {
  const { t } = useContext(LocalizationContext);
  const { setFieldValue, setValues, setFieldTouched, getFieldMeta, values, initialValues } =
    useFormikContext<GalleryFormValues>();

  const [projectsQueryParams, setProjectsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);

  const { data: meData } = useQuery(ME);

  const hasCRM = useMemo(() => !!meData?.me?.hasCRM, [meData]);
  const galleryId = values.id;

  const {
    data: projectsData,
    loading: isProjectsLoading,
    fetchMore: fetchMoreProjects,
  } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    skip: !hasCRM,
    variables: {
      where: projectsQueryParams,
    },
  });

  // Fetch slected gallery contacts to show them even if not in the current pagination
  const { data: galleryContactsData } = useQuery(GET_GALLERY_CONTACTS, {
    fetchPolicy: 'cache-first',
    skip: !galleryId,
    variables: {
      where: {
        id: galleryId,
      },
    },
  });

  const handleLoadMoreProjects = useCallback(async () => {
    const params = { ...projectsQueryParams, page: projectsQueryParams.page + 1 };
    await fetchMoreProjects({
      variables: {
        where: params,
      },
    });

    setProjectsQueryParams(params);
  }, [fetchMoreProjects, projectsQueryParams]);

  const handleSearchProject = useCallback(async (searchTerms: string) => {
    setProjectsQueryParams({
      ...EDGES_PARAMS,
      search: searchTerms.length > 0 ? searchTerms : undefined,
    });
  }, []);

  const projects = useMemo(() => {
    if (projectsData?.getProjects.edges) {
      return uniqBy(projectsData.getProjects.edges, 'id');
    }

    return [];
  }, [projectsData?.getProjects.edges]);

  const galleryContacts = useMemo(
    () => (galleryContactsData?.getGallery?.__typename === 'GalleryAdmin' ? galleryContactsData.getGallery : null),
    [galleryContactsData?.getGallery],
  );

  // Merge the paginated contacts and the gallery contacts
  const contacts = useMemo(() => {
    const mergedContacts = [];
    if (galleryContacts) {
      if (galleryContacts.contact) {
        mergedContacts.push(galleryContacts.contact);
      }
      if (galleryContacts.secondaryContacts?.length) {
        mergedContacts.push(...galleryContacts.secondaryContacts);
      }
    }

    if (projectsData?.getProjects.edges?.length) {
      const projectContacts = projectsData.getProjects.edges.map(({ contact }) => contact);
      const projectSecondaryContacts = projectsData.getProjects.edges.map(({ secondaryContacts }) => secondaryContacts);
      mergedContacts.push(...projectContacts);
      mergedContacts.push(...flatten(projectSecondaryContacts));
    }

    return compact(mergedContacts);
  }, [galleryContacts, projectsData?.getProjects.edges]);

  const hasFetchAllProjects = useMemo(
    () => projects?.length === projectsData?.getProjects?._count,
    [projectsData, projects],
  );

  const isOldAccessCodeGallery = useMemo(
    () => values.accessPolicy === GalleryAccessPolicy.OLD_ACCESS_CODE,
    [values.accessPolicy],
  );

  const isOldPublicGallery = useMemo(
    () => values.accessPolicy === GalleryAccessPolicy.OLD_PUBLIC,
    [values.accessPolicy],
  );

  const isAccessCodeInputAvailable = useMemo(
    () => values.accessPolicy === GalleryAccessPolicy.ACCESS_CODE,
    [values.accessPolicy],
  );

  // Set the project data if the projectId is set
  useEffect(() => {
    if (values.projectId) {
      const project = projects.find(project => project.id === values.projectId);
      if (!project) {
        return;
      }
      setValues(oldValues => ({
        ...oldValues,
        name: oldValues.name || project.name,
        url: oldValues.url || kebabCase(project.name),
        contactId: oldValues.contactId || project.contactId,
        secondaryContactIds: oldValues.secondaryContactIds || project.secondaryContactsIds,
        shootedAt: oldValues.shootedAt || project.startTime,
      }));
    }
  }, [projects, setValues, values.projectId]);

  // Reset the availableAt date if the gallery is online and the availableAt date is in the future
  useEffect(() => {
    if (
      values.status === GalleryStatus.ONLINE &&
      values.availableAt &&
      dayjs(values.availableAt).isSameOrAfter(dayjs())
    ) {
      setFieldValue('availableAt', null);
    }
  }, [values.status, values.availableAt, setFieldValue]);

  useEffect(() => {
    if (initialValues.accessCode && initialValues.contactId === values.contactId) {
      // Reset the access code if the contact is changed
      setFieldValue('accessCode', initialValues.accessCode);
    } else if (!!values.contactId) {
      // Generate a new access code if the contact is changed
      setFieldValue('accessCode', generateId({ size: 6 }));
    } else {
      setFieldValue('accessCode', '');
    }
  }, [initialValues.accessCode, initialValues.contactId, values.contactId, setFieldValue]);

  // Set the contactId to null if there was a contactId and the contact is removed
  useEffect(() => {
    if (initialValues.contactId && !values.contactId) {
      setFieldValue('contactId', null);
    }
  }, [initialValues.contactId, values.contactId, setFieldValue]);

  return (
    <Flex vertical>
      {hasCRM && (
        <Form.Item label={t('app.gallery.project')} name="projectId" hasFeedback={false}>
          <Select
            name="projectId"
            size="large"
            showSearch
            optionFilterProp="children"
            onSearch={handleSearchProject}
            loadMore={handleLoadMoreProjects}
            hasFetchAll={hasFetchAllProjects}
            loading={isProjectsLoading}
            getPopupContainer={trigger => trigger.parentNode}
          >
            {projects.map(project => (
              <Select.Option key={project.id} value={project.id} title={''}>
                {project.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}

      <Flex gap="middle">
        <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
          <Input
            name="name"
            placeholder={t('app.common.name')}
            size="large"
            onChange={({ target: { value } }) => {
              setFieldValue('name', value);
              const { touched: isTouched, initialValue } = getFieldMeta('url');
              if (!initialValue && !isTouched) {
                setFieldValue('url', kebabCase(value));
              }
            }}
          />
        </Form.Item>
        <Form.Item label={t('app.common.customUrl')} name="url" hasFeedback={false}>
          <Input
            name="url"
            placeholder={t('app.common.customUrl')}
            size="large"
            onBlur={({ target: { value } }) => setFieldValue('url', kebabCase(value))}
            onChange={({ target: { value } }) => {
              setFieldTouched('url', true);
              setFieldValue('url', value);
            }}
          />
        </Form.Item>
      </Flex>

      <Flex gap="middle">
        <SelectContact
          label={t('app.common.contact')}
          name="contactId"
          required={isAccessCodeInputAvailable || isOldAccessCodeGallery}
          loadedContacts={contacts}
          excludedContacts={values?.secondaryContactIds || []}
          value={values.contactId ? [values.contactId] : []}
        />
        {!isOldPublicGallery && (
          <Form.Item
            label={isOldAccessCodeGallery ? t('app.gallery.oldAccessCode') : t('app.gallery.accessCode')}
            name="accessCode"
            required={isAccessCodeInputAvailable}
            hasFeedback={false}
          >
            <Input name="accessCode" placeholder={t('app.gallery.accessCode')} size="large" />
          </Form.Item>
        )}
      </Flex>
      {
        // Show a warning if the access code is modified for the same contact
        !!galleryId &&
          !!initialValues.accessCode &&
          values.accessCode !== initialValues.accessCode &&
          values.contactId === initialValues.contactId && (
            <Flex gap="small">
              <Icon name="warning" size={24} />
              <p>{t('app.gallery.warning.accessCodeModification')}</p>
            </Flex>
          )
      }

      <SelectContact
        label={t('app.common.secondaryContacts')}
        name="secondaryContactIds"
        loadedContacts={contacts}
        excludedContacts={values.contactId ? [values.contactId] : []}
        value={values.secondaryContactIds || []}
        multiple
      />

      {!galleryId ? (
        <Flex gap="middle">
          <Form.Item label={t('app.gallery.availableAt')} name="availableAt" hasFeedback={false}>
            <DatePicker
              showNow={false}
              name="availableAt"
              size="large"
              showTime={{ format: 'HH:mm', defaultValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
              format="LLL"
            />
          </Form.Item>
          <Form.Item label={t('app.gallery.expiredAt')} name="expiredAt" hasFeedback={false}>
            <DatePicker
              showNow={false}
              name="expiredAt"
              size="large"
              showTime={{ format: 'HH:mm', defaultValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
              format="LLL"
            />
          </Form.Item>
        </Flex>
      ) : (
        <>
          <Flex gap="middle">
            <Form.Item label={t('app.common.status')} name="status" required hasFeedback={false}>
              <Select name="status" size="large" getPopupContainer={trigger => trigger.parentNode}>
                {Object.keys(GalleryStatus)
                  .filter(status => status !== GalleryStatus.ARCHIVED)
                  .map(status => (
                    <Select.Option key={status} value={status} title={''}>
                      {t(`app.gallery.status.${status.toLowerCase()}`)}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>

            {values.status === GalleryStatus.OFFLINE && (
              <Form.Item label={t('app.gallery.availableAt')} name="availableAt" hasFeedback={false}>
                <DatePicker
                  showNow={false}
                  name="availableAt"
                  size="large"
                  showTime={{ format: 'HH:mm', defaultValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
                  format="LLL"
                />
              </Form.Item>
            )}
          </Flex>
          <Form.Item label={t('app.gallery.expiredAt')} name="expiredAt" hasFeedback={false}>
            <DatePicker
              showNow={false}
              name="expiredAt"
              size="large"
              showTime={{ format: 'HH:mm', defaultValue: dayjs('00:00:00', 'HH:mm:ss'), minuteStep: 5 }}
              format="LLL"
            />
          </Form.Item>
        </>
      )}

      <Flex gap="middle">
        <Form.Item label={t('app.gallery.shootedAt')} name="shootedAt" hasFeedback={false}>
          <DatePicker name="shootedAt" size="large" format="YYYY-MM-DD" minuteStep={5} />
        </Form.Item>
        <Form.Item label={t('app.gallery.locale.label')} name="locale" required hasFeedback={false}>
          <Select name="locale" size="large" getPopupContainer={trigger => trigger.parentNode}>
            {Object.keys(Locale).map(lang => (
              <Select.Option key={lang} value={lang.toLowerCase()} title={''}>
                {t(`app.locale.${lang.toLowerCase()}`)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Flex>

      <Form.Item
        label={t('app.gallery.inPortfolio')}
        name="inPortfolio"
        className="ant-form-item--switch"
        hasFeedback={false}
      >
        <Switch name="inPortfolio" />
      </Form.Item>
    </Flex>
  );
};

export default GalleryInfoStep;
