import React, { useCallback, useContext, useMemo } from 'react';

import { useMutation } from '@apollo/client';
import { App, Button, Flex, Popconfirm, Table, TablePaginationConfig, Tag, Tooltip, Typography } from 'antd';
import { useTheme } from 'antd-style';
import { ColumnsType } from 'antd/es/table';

import { compact } from 'lodash';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';

import RoundButton from 'Components/Molecules/Buttons/RoundButton';

import { AccessType, EditAccessFormPayload, EditAccessFormValues } from 'Forms/Access';
import { SendEmailPayload } from 'Forms/SendEmail';

import { Colors } from 'Themes';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';
import { useContactModal } from 'Hooks/useContactModal';
import { useCurrency } from 'Hooks/useCurrency';

import {
  EmailModelType,
  EmailTemplateAssociatedModel,
  GalleryAccessPolicy,
  SendEmailInput,
} from 'Operations/__generated__/graphql';

import { SEND_EMAIL } from 'Operations/Mutations/Email/SendEmail';

export interface AccessCodeProps {
  code: string;
  isDefault: boolean;
  groupName?: string | null;
  contactId?: number | null;
  contact?: {
    displayName?: string;
    email?: string;
  };
  stats?: {
    ordersCount: number | undefined;
    ordersAmount: number | undefined;
  } | null;
}

interface Props {
  total: number;
  isLoading: boolean;
  paginationSize?: number;
  data?: AccessCodeProps[];
  type: AccessType;
  galleryId: number;
  galleryUrl: string;
  galleryContactId: number | undefined;
  galleryAccessPolicy: GalleryAccessPolicy;
  onEdit?: (data: EditAccessFormPayload) => Promise<void>;
  onDelete?: (code: string) => Promise<void>;
  onChange: (props: TablePaginationConfig) => void;
}

const AccessTable = ({
  data,
  type,
  galleryUrl,
  galleryId,
  galleryContactId,
  galleryAccessPolicy,
  total,
  isLoading,
  paginationSize,
  onEdit,
  onDelete,
  onChange,
}: Props) => {
  const { openModal, closeModal } = useModals();
  const { message } = App.useApp();
  const { symbol } = useCurrency();
  const { t } = useContext(LocalizationContext);
  const theme = useTheme();

  const [sendEmail] = useMutation(SEND_EMAIL);
  const { updateContact } = useContactModal();

  const handleSendEmail = useCallback(
    async ({
      values: { attachmentName: _attName, attachmentUrl: _attUrl, ...values },
      formikBag,
    }: SendEmailPayload) => {
      try {
        if (!values.contactId) {
          throw new Error('Contact ID is required');
        }
        const sendEmailInput: SendEmailInput = {
          contactId: values.contactId,
          content: values.content,
          modelId: galleryId,
          modelType: EmailModelType.GALLERYACCESSCODE,
          title: values.title,
          attachment: values.attachment,
          secondaryContactIds: values.secondaryContactIds,
          templateId: values.templateId,
        };
        await sendEmail({
          variables: { data: sendEmailInput },
        });

        formikBag.setSubmitting(false);
        closeModal();

        message.success(t('app.message.gallery.sendEmail.success'));
      } catch (error) {
        console.log(error);

        message.error(t('app.message.error.somethingWentWrong'));
        formikBag.setSubmitting(false);
      }
    },
    [closeModal, galleryId, sendEmail, t],
  );

  const copyToClipboard = useCallback(
    ({ value }: { value: string }) => {
      navigator.clipboard.writeText(value);
      message.success(t('app.common.clipboard.copy'));
    },
    [t],
  );

  const handleOpenEmailModal = useCallback(
    async (associatedModel: EmailTemplateAssociatedModel, contactId?: number) => {
      openModal('SEND_EMAIL', {
        onSubmit: handleSendEmail,
        defaultValues: {
          title: '',
          content: '',
          templateAssociatedModel: associatedModel,
          contactId: contactId ? contactId : galleryContactId,
        },
        galleryId,
        contactId,
      });
    },
    [galleryContactId, handleSendEmail, openModal],
  );

  const columns: ColumnsType<AccessCodeProps> = useMemo(
    () =>
      compact([
        {
          title: t('app.common.actions'),
          key: 'operation',
          width: 200,
          render: (_, record) => (
            <Container key={record.code} gap={8}>
              {onEdit && (
                <RoundButton
                  icon="edit"
                  tooltipTitle={t('app.access.editAccess')}
                  onClick={() => {
                    openModal('ACCESS_CODE', {
                      name: 'edit',
                      onSubmit: onEdit,
                      defaultValues: {
                        name: record.groupName ? record.groupName : '',
                        code: record.code,
                        oldCode: record.code,
                        accessType: type,
                      },
                      galleryAccessPolicy,
                    });
                  }}
                />
              )}
              <RoundButton
                onClick={() => {
                  window.open(`${galleryUrl}?accessCode=${record.code}`, '_blank');
                }}
                icon="external-link"
                tooltipTitle={t('app.common.showGallery')}
              />
              {type === 'client' && (
                <RoundButton
                  icon="send"
                  tooltipTitle={t('app.access.sendAccess')}
                  onClick={() => {
                    handleOpenEmailModal(
                      EmailTemplateAssociatedModel.GALLERYACCESSCODE,
                      record.contactId ? record.contactId : undefined,
                    );
                  }}
                />
              )}
              {type === 'group' && !record.isDefault && (
                <Popconfirm
                  title={t('app.access.delete.confirm')}
                  onConfirm={() => {
                    onDelete?.(record.code);
                  }}
                >
                  <RoundButton icon="delete" tooltipTitle={t('app.access.delete.group')} danger />
                </Popconfirm>
              )}
            </Container>
          ),
        },
        {
          title:
            type === 'group'
              ? t('app.common.name')
              : type === 'visitor'
              ? t('app.common.email')
              : t('app.common.name') + ' (' + t('app.common.email') + ')',
          key: 'name',
          dataIndex: 'name',
          render: (_, record) =>
            type === 'group' ? (
              <>
                {record.isDefault ? (
                  <Tooltip title={t('app.access.defaultAccess')} placement="topLeft">
                    <Flex>
                      <Icon name="star" color={Colors.secondaryOptional} size={20} />
                      <span>{record.groupName}</span>
                    </Flex>
                  </Tooltip>
                ) : (
                  record.groupName
                )}
              </>
            ) : type === 'visitor' ? (
              record.contact?.displayName
            ) : (
              <Button type="link" onClick={() => record.contactId && updateContact(record.contactId)}>
                {record.contact?.displayName}
                {record.contact?.email && ` (${record.contact?.email})`}
              </Button>
            ),
        },
        {
          title: t('app.common.code'),
          key: 'code',
          dataIndex: 'code',
          width: 200,
          render: code => (
            <Flex align="center" gap="small">
              <Tag>
                <Typography.Text copyable>{code}</Typography.Text>
              </Tag>
            </Flex>
          ),
        },
        type === 'client' && {
          title: (
            <Flex gap="small">
              {t('app.gallery.access.groupTitle', { count: 1 })}
              <Icon
                name="info-circle"
                color={theme.colorSecondary}
                style={{
                  cursor: 'pointer',
                }}
                onClick={() =>
                  openModal('INFO', {
                    title: t('app.gallery.access.groupTitle', { count: 1 }),
                    content: t('app.access.parentGroup.description'),
                  })
                }
              />
            </Flex>
          ),
          key: 'group',
          dataIndex: 'group',
          render: (_, record) => record.groupName || '-',
        },
        type === 'client' && {
          title: t('app.common.order', { count: 2 }) + ' (' + t('app.common.amount') + ')',
          key: 'stats',
          width: 120,
          render: record => (
            <Container align="center" gap={8}>
              <span>
                {record.stats.ordersCount} ({record.stats.ordersAmount.toFixed(2)}
                {symbol})
              </span>
            </Container>
          ),
        },
      ]),
    [
      copyToClipboard,
      galleryAccessPolicy,
      galleryUrl,
      handleOpenEmailModal,
      onDelete,
      onEdit,
      openModal,
      symbol,
      t,
      type,
    ],
  );
  return (
    <Table
      className={`ant-table--bordered`}
      size="middle"
      rowKey="id"
      columns={columns}
      dataSource={data}
      loading={isLoading}
      pagination={{
        total,
        pageSize: paginationSize,
        showSizeChanger: false,
        position: ['bottomCenter'],
      }}
      onChange={pagination => onChange(pagination)}
    />
  );
};

export default AccessTable;
