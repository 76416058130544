import { gql } from 'Operations/__generated__';

export const GET_GROUP_ACCESS_CODES = gql(/* GraphQL */ `
  query GetGroupAccessCodes($where: GroupAccessCodesWhereType!, $paginate: PaginatedInput) {
    getGroupAccessCodes(where: $where, paginate: $paginate) {
      edges {
        ...AccessCodeCoreFields
      }
      _count
    }
  }
`);
