import React from 'react';

import { Card, Flex, Radio } from 'antd';
import { createStyles } from 'antd-style';

import Icon from 'Components/Atoms/Icon';

interface Props {
  children: React.ReactNode;
  title: string;
  isSelected?: boolean;
  onClick?: () => void;
  icon: any;
  align?: React.CSSProperties['alignItems'];
  pointer?: boolean;
  bordered?: boolean;
}

const useStyles = createStyles(({ css, token }) => ({
  card: css`
    overflow: visible !important;
    &.active {
      border-color: ${token.colorPrimary};
    }
    &.no-shadow {
      box-shadow: none !important;
    }
    &.no-padding {
      .ant-card-body {
        padding: 0 !important;
      }
    }
  `,
  noPointer: css`
    cursor: default !important;
  `,
  title: css`
    font-size: ${token.fontSizeXL}px;
    font-weight: ${token.fontWeightStrong};
    margin: 0;
  `,
  radio: css`
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 1px solid ${token.colorBorder};
    transition: all 0.3s;

    svg {
      opacity: 0;
    }

    &.active {
      border-color: ${token.colorPrimaryBorder};
      background-color: ${token.colorPrimary};
      color: ${token.colorText};

      svg {
        opacity: 1;
      }
    }
  `,
}));

const SelectCard = ({
  children,
  icon,
  title,
  isSelected,
  align = 'center',
  bordered = true,
  pointer = true,
  onClick,
}: Props) => {
  const { styles, cx } = useStyles();

  return (
    <Card
      size="small"
      onClick={onClick}
      hoverable={onClick && bordered}
      className={cx(
        styles.card,
        isSelected && 'active',
        !bordered && 'no-shadow',
        !pointer && styles.noPointer,
        !bordered && 'no-padding',
      )}
      bordered={bordered}
    >
      <Flex gap="middle" align={align}>
        <img src={icon} width={48} />
        <Flex vertical gap="small" flex={1}>
          <h2 className={styles.title}>{title}</h2>
          <div>{children}</div>
        </Flex>
        {onClick && (
          <div className={cx(styles.radio, isSelected && 'active', !pointer && styles.noPointer)}>
            <Icon name="check" size={24} />
          </div>
        )}
      </Flex>
    </Card>
  );
};

export default SelectCard;
