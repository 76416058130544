import React, { useContext, useState } from 'react';

import { useQuery } from '@apollo/client';

import { Formik } from 'formik';
import { Form } from 'formik-antd';

import Select from 'Components/Molecules/Form/Select';

import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { OrderType } from 'Operations/__generated__/graphql';

import { GET_CATALOGS } from 'Operations/Queries/Catalog/GetCatalogs';

const PER_PAGE = 20;

const FormItem = styled(Form.Item)`
  & .ant-form-item-control {
    min-width: 200px;
  }
  & .ant-col.ant-form-item-label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

interface Props {
  catalogId?: number | null;
  onChange: (catalogId: number | null) => void;
}

const CatalogSelection = ({ catalogId, onChange }: Props) => {
  const { t } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);

  const { data: catalogsData, loading: isCatalogsLoading } = useQuery(GET_CATALOGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: {
        page,
        perPage: PER_PAGE,
        order: OrderType.ASC,
      },
    },
  });

  return (
    <Formik
      initialValues={{
        catalogId,
      }}
      onSubmit={() => {
        return;
      }}
      enableReinitialize
    >
      <Form layout="inline">
        <FormItem label={t('app.common.catalog', { count: 1 })} name="catalogId">
          <Select
            name="catalogId"
            size="large"
            onChange={value => onChange(value || null)}
            loadMore={() => setPage(page + 1)}
            hasFetchAll={catalogsData?.getCatalogs._count === catalogsData?.getCatalogs.edges.length}
            loading={isCatalogsLoading}
            getPopupContainer={trigger => trigger.parentNode}
            allowClear
          >
            {catalogsData?.getCatalogs.edges.map(catalog => (
              <Select.Option key={catalog.id} value={catalog.id} title={''}>
                {catalog.name}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
      </Form>
    </Formik>
  );
};

export default CatalogSelection;
