import React, { useContext } from 'react';

import { DeepPartial } from '@apollo/client/utilities';
import { Drawer, Spin } from 'antd';
import { Link } from 'react-router-dom';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Title from 'Components/Atoms/Title';

import Comments from 'Components/Molecules/Comments';
import InfoItem from 'Components/Molecules/InfoItem';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import dayjs from 'Services/DayjsService';

import { PhotoAdmin, PhotoCharacteristic } from 'Operations/__generated__/graphql';

interface Props {
  isVisible: boolean;
  isLoading: boolean;
  onCloseDrawer: () => void;
  photo?: DeepPartial<PhotoAdmin>;
  isGalleryButtonVisible?: boolean;
}

const DrawerContent = styled.div`
  padding: ${Metrics.baseMargin}px;
  padding-top: 0;
`;

const SpinContainer = styled(Container)`
  width: 100%;
  height: 300px;
`;

const DrawerImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  margin: 0 auto;
  margin-top: ${Metrics.baseMargin}px;
`;

const DrawerImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
`;

const PhotoDrawer = ({ isVisible, onCloseDrawer, photo, isLoading, isGalleryButtonVisible }: Props) => {
  const { t } = useContext(LocalizationContext);

  return (
    <Drawer
      open={isVisible}
      title={<Title level="h2">{photo ? photo.name : t('app.common.loading')}</Title>}
      placement="right"
      onClose={onCloseDrawer}
      width={Metrics.drawer.photo}
      closeIcon={<Icon name="close" />}
    >
      {isLoading && (
        <SpinContainer justify="center" align="center">
          <Spin size="large" />
        </SpinContainer>
      )}
      {photo &&
        photo?.asset &&
        photo?.asset.noWmThumbLarge &&
        photo.gallery &&
        photo.id &&
        photo.galleryId &&
        photo.statuses && (
          <DrawerContent>
            <InfoItem label={t('app.common.photosViewCount', { count: 1 })} text={photo.viewCount || 0} />
            <InfoItem label={t('app.photo.uploadedAt')} text={dayjs(photo.uploadedAt).format('LLL')} />
            {isGalleryButtonVisible && (
              <InfoItem
                label={t('app.common.gallery')}
                text={
                  <Link to={`/app/galleries/${photo.galleryId}`} onClick={onCloseDrawer}>
                    {photo.gallery.name}
                  </Link>
                }
              />
            )}
            <DrawerImageContainer>
              <DrawerImage src={photo.asset.noWmThumbLarge.downloadUrl} alt={`${photo.name}`} />
            </DrawerImageContainer>
            {photo.isWatermarked && photo.asset.thumbLarge && (
              <DrawerImageContainer>
                <DrawerImage src={photo.asset.thumbLarge.downloadUrl} alt={`${photo.name}`} />
              </DrawerImageContainer>
            )}
            <Comments
              galleryId={photo.galleryId}
              photoId={photo.id}
              statuses={photo.statuses as PhotoCharacteristic[]}
            />
          </DrawerContent>
        )}
    </Drawer>
  );
};

export default PhotoDrawer;
