import { gql } from 'Operations/__generated__';

export const UPDATE_GALLERY_PRESET_SPOTIFY_URI = gql(/* GraphQL */ `
  mutation UpdateGalleryPresetSpotifyUri($where: IdInput!, $data: GalleryUpdateSpotifyUriInputType!) {
    updateGalleryPresetSpotifyUri(where: $where, data: $data) {
      id
      spotifyUri
    }
  }
`);
