import { gql } from 'Operations/__generated__';

export const GET_VISITORS_EMAIL = gql(/* GraphQL */ `
  query GetVisitorsEmail($where: GalleryIdInput!, $paginate: PaginatedInput) {
    getVisitorsEmail: getVisitors(where: $where, paginate: $paginate) {
      edges {
        email
      }
    }
  }
`);
