import React, { useContext } from 'react';

import { Col, Flex, Row } from 'antd';
import { createStyles } from 'antd-style';

import clsx from 'clsx';

import { TinyColor } from '@ctrl/tinycolor';

import Icon, { IconName } from 'Components/Atoms/Icon';

import { EditGalleryThemeFormValues } from 'Forms/GalleryTheme';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { getReadableColor } from 'Helpers/TransformColor';

import { useStyles } from './styles';

const useGalleryStyles = createStyles<EditGalleryThemeFormValues>(
  ({ css }, { primaryColor, menuBackgroundColor, buttonRadius, backgroundColor }) => ({
    menuContainer: css`
      position: relative;
      height: 80px;
      z-index: 11;
      border-bottom: 1px solid #b8b8b8;
      margin: 0;
      background-color: ${new TinyColor(menuBackgroundColor.toRgbString()).setAlpha(1).toRgbString()};
    `,
    menu: css`
      display: flex;
      list-style: none;
      height: 100%;
      align-items: center;
      padding: 0;
      li {
        position: relative;
        height: 100%;
        padding: 0 16px;
        max-width: 140px;
        display: flex;
        align-items: center;
        font-size: 16px;
        color: ${new TinyColor(primaryColor.toRgbString()).setAlpha(0.75).toRgbString()};
        span {
          display: flex;
          align-items: center;
          gap: 4px;
        }
        &.active {
          color: ${primaryColor.toRgbString()};
          font-weight: bold;

          border-bottom: 2px solid ${primaryColor.toRgbString()};
        }
      }
    `,
    selectCard: css`
      padding: 16px;
      box-shadow: 0 4px 24px
        ${getReadableColor({
          backgroundColor: backgroundColor.toRgbString(),
          includeFallbackColors: true,
        })
          .setAlpha(0.2)
          .toRgbString()};
      border-radius: ${buttonRadius}px;
      background-color: ${backgroundColor.toRgbString()};
      color: ${primaryColor.toRgbString()};
    `,
    imageOverlay: css`
      position: absolute;
      inset: 0;
    `,
    selectPhotoButton: css`
      position: absolute;
      top: 8px;
      right: 16px;
    `,
  }),
);

const GalleryPreview = (props: EditGalleryThemeFormValues) => {
  const { t } = useContext(LocalizationContext);
  const { styles } = useStyles(props);
  const { styles: galleryStyles } = useGalleryStyles(props);

  // TS check
  if (typeof styles === 'string' || typeof galleryStyles === 'string') {
    return null;
  }

  const ImagePreview = ({
    isActive,
    isHover,
    icon = 'gallery-app-plus',
  }: {
    isActive?: boolean;
    isHover?: boolean;
    icon?: IconName;
  }) => (
    <Col xs={24} md={24} lg={24} xl={12} xxl={8} className={styles.imageContainer}>
      {(!!isActive || !!isHover) && (
        <div className={galleryStyles.imageOverlay}>
          <button
            disabled
            className={clsx(galleryStyles.selectPhotoButton, styles.button, 'icon', 'small', isActive && 'primary')}
          >
            <Icon name={icon} size={20} />
          </button>
        </div>
      )}
      <img src={Images.coverMissing} />
    </Col>
  );

  return (
    <Flex vertical className={styles.container}>
      {/* Menu */}
      <Flex gap="small" justify="space-between" className={galleryStyles.menuContainer}>
        <ul className={galleryStyles.menu}>
          <li>
            <span>
              <Icon name="gallery-app-home" size={20} />
            </span>
          </li>
          <li className="active">
            <span>{t('app.common.gallery')}</span>
          </li>
          <li>
            <span>{t('app.common.photos')}</span>
          </li>
        </ul>
        <ul className={galleryStyles.menu}>
          <li>
            <span>
              <Icon name="gallery-app-heart" size={20} />
              {t('galleryPreview.gallery.liked')}
            </span>
          </li>
          <li>
            <span>
              <Icon name="gallery-app-cart" size={20} />
              <p className={styles.tag}>3</p>
            </span>
          </li>
          <li>
            <span>
              <Icon name="gallery-app-user" size={20} />
            </span>
          </li>
        </ul>
      </Flex>
      {/* Content */}
      <Flex vertical gap="middle" align="flex-start" className="ant-flex--padding">
        <Flex
          justify="space-between"
          align="center"
          gap="middle"
          className={styles.subResponsiveContainer}
          style={{ width: '100%' }}
        >
          <button disabled className={clsx(styles.button, 'primary')}>
            {t('galleryPreview.gallery.selectAllPhotos')}
          </button>
          <Flex className={galleryStyles.selectCard} align="center" justify="center" gap="small">
            <span>{t('galleryPreview.gallery.selectedPhotos')}</span>
            <button disabled className={clsx(styles.button, 'primary', 'icon')}>
              <Icon size={18} name="gallery-app-cart" />
            </button>
            <button disabled className={clsx(styles.button, 'icon')}>
              <Icon size={18} name="close" />
            </button>
          </Flex>
        </Flex>
        <h2 className={styles.title}>
          <Flex align="center" justify="center" gap="middle">
            {t('app.common.gallery')}
            <button disabled className={clsx(styles.button, 'icon', 'small')}>
              <Icon name="gallery-app-plus" />
            </button>
          </Flex>
        </h2>
        <Row gutter={[16, 16]}>
          <ImagePreview isHover />
          <ImagePreview isActive icon="check" />
          <ImagePreview isActive icon="check" />
          <ImagePreview />
          <ImagePreview />
          <ImagePreview />
          <ImagePreview />
          <ImagePreview />
          <ImagePreview />
        </Row>
      </Flex>
    </Flex>
  );
};

export default GalleryPreview;
