import React, { useContext } from 'react';

import { Drawer, Empty, Spin } from 'antd';

import InfiniteScroll from 'react-infinite-scroll-component';

import { camelCase } from 'lodash';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Title from 'Components/Atoms/Title';

import NotificationCard from 'Components/Molecules/NotificationCard';

import { Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import getNotificationIcon from 'Helpers/getNotificationIcon';

import { GetNotificationsQuery, NotificationAction } from 'Operations/__generated__/graphql';

interface Props {
  isVisible: boolean;
  notifications: GetNotificationsQuery['getNotifications']['edges'];
  onCloseDrawer: () => void;
  loadMore: () => void;
  hasMore: boolean;
}

type GetNotificationExtraMetaProps = Pick<GetNotificationsQuery['getNotifications']['edges'][0], 'action' | 'meta'>;

const DrawerContainer = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: ${Metrics.smallMargin}px 0;
`;

const GALLERY_URL_PATH = '/app/galleries/';

const NotificationDrawer = ({ isVisible, onCloseDrawer, notifications, hasMore, loadMore }: Props) => {
  const { t } = useContext(LocalizationContext);

  const getNotificationExtraMeta = React.useCallback(
    ({ action, meta }: GetNotificationExtraMetaProps) => {
      switch (action) {
        case NotificationAction.NEW_PICKLIST:
          return {
            contact:
              !!meta.firstname.length || !!meta.lastname.length
                ? `${meta.firstname} ${meta.lastname}`
                : t('app.common.visitor'),
          };

        default:
          return {};
      }
    },
    [t],
  );

  return (
    <Drawer
      open={isVisible}
      title={<Title level="h2">{t('app.common.notification', { count: 2 })}</Title>}
      placement="left"
      onClose={onCloseDrawer}
      width={Metrics.drawer.notification}
      closeIcon={<Icon name="close" />}
    >
      <DrawerContainer id="NotificationDrawer">
        {notifications.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        <InfiniteScroll
          dataLength={notifications.length}
          next={loadMore}
          loader={
            <Container justify="center">
              <Spin />
            </Container>
          }
          hasMore={hasMore}
          scrollableTarget="NotificationDrawer"
        >
          {notifications.map(({ id, action, meta, gallery, createdAt, isRead }) => (
            <NotificationCard
              key={id}
              icon={getNotificationIcon(action)}
              content={t(`app.notification.${camelCase(action)}.description`, {
                galleryUrl: gallery ? `${GALLERY_URL_PATH}${gallery.id}` : '#',
                gallery: gallery?.name,
                ...meta,
                ...getNotificationExtraMeta({ action, meta }),
              })}
              date={createdAt}
              isRead={isRead}
              onCloseDrawer={onCloseDrawer}
            />
          ))}
        </InfiniteScroll>
      </DrawerContainer>
    </Drawer>
  );
};

export default NotificationDrawer;
