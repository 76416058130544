import React, { useCallback, useContext, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Button, Image, List } from 'antd';

import Card from 'Components/Atoms/Card';
import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Text from 'Components/Atoms/Text';

import Comments from 'Components/Molecules/Comments';

import { Colors, Metrics } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { GET_LATEST_COMMENTED_PHOTOS } from 'Operations/Queries/Photo/GetLatestCommentedPhotos';

const ImageContainer = styled.div`
  position: relative;
`;
const IconsContainer = styled.div`
  position: absolute;
  top: 8px;
  left: 8px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
`;

interface GalleryAccessTabProps {
  galleryId: number;
}

const PER_PAGE = 10;

const GalleryCommentsTab = ({ galleryId }: GalleryAccessTabProps) => {
  const { t } = useContext(LocalizationContext);
  const [page, setPage] = useState(1);

  const { data, loading: isLoading } = useQuery(GET_LATEST_COMMENTED_PHOTOS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        galleryId,
        page,
        perPage: PER_PAGE,
      },
    },
  });

  const hasFetchAll = useMemo(
    () =>
      !isLoading &&
      data?.getLatestCommentedPhotos &&
      data.getLatestCommentedPhotos._count <= data.getLatestCommentedPhotos.edges.length,
    [data?.getLatestCommentedPhotos, isLoading],
  );

  const handleLoadMorePhotos = useCallback(() => {
    setPage(Math.floor((data?.getLatestCommentedPhotos.edges.length || 0) / PER_PAGE) + 1);
  }, [data?.getLatestCommentedPhotos.edges.length]);

  return (
    <Container direction="column">
      <List
        style={{ width: '100%' }}
        dataSource={data?.getLatestCommentedPhotos.edges}
        loading={isLoading}
        loadMore={
          !hasFetchAll ? (
            <Container justify="center" padding="baseMargin">
              <Button onClick={handleLoadMorePhotos}>{t('app.common.loadMore')}</Button>
            </Container>
          ) : undefined
        }
        renderItem={photo =>
          'name' in photo && (
            <List.Item key={photo.id}>
              <Container gap={24} block>
                <Container direction="column" gap={Metrics.smallMargin} style={{ width: 150 }}>
                  <ImageContainer>
                    <Image
                      src={photo.asset?.thumbLarge?.downloadUrl}
                      preview={{
                        src: photo.asset?.assetWeb?.downloadUrl || undefined,
                      }}
                    />
                    <IconsContainer>{photo.isOrdered && <Icon name="purchased" color={Colors.black} />}</IconsContainer>
                  </ImageContainer>
                  <Text size="small">{photo.name}</Text>
                </Container>
                <Card style={{ flex: 1 }} padding="smallMargin" shadow>
                  <Comments galleryId={galleryId} photoId={photo.id} statuses={photo.statuses} />
                </Card>
              </Container>
            </List.Item>
          )
        }
      />
    </Container>
  );
};

export default GalleryCommentsTab;
