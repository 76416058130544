import React, { useCallback, useContext, useMemo, useState } from 'react';

import { Alert, Button, Flex, Radio, RadioChangeEvent } from 'antd';

import { FormikBag, FormikProps, withFormik } from 'formik';
import { Form, Input } from 'formik-antd';

import SelectContact from 'Components/Molecules/Inputs/SelectContact';
import SelectGroup from 'Components/Molecules/Inputs/SelectGroup';

import { LocalizationContext } from 'i18n';

import useGallery from 'Hooks/useGallery';

import yup from 'Services/YupService';

import {
  AccessCodeCoreFieldsFragment,
  ContactCoreFieldsFragment,
  GalleryAccessPolicy,
} from 'Operations/__generated__/graphql';

export interface NewPicklistFormValues {
  name: string;
  description?: string;
  contactId?: number;
  groupCodes?: string[];
}

export interface EditPicklistFormValues extends NewPicklistFormValues {
  id: number;
}

interface PicklistFormProps {
  galleryId: number;
  contact?: ContactCoreFieldsFragment;
  groups?: AccessCodeCoreFieldsFragment[];
}

type AccessSelect = 'contact' | 'group';

const Picklist = <T extends NewPicklistFormValues | EditPicklistFormValues>({
  contact,
  groups,
  values,
  isSubmitting,
  initialValues,
  galleryId,
  setFieldValue,
  errors,
}: FormikProps<T> & PicklistFormProps) => {
  const { gallery } = useGallery({ id: galleryId });

  const [accessSelect, setAccessSelect] = useState<AccessSelect>(
    initialValues.groupCodes?.length ? 'group' : 'contact',
  );
  const { t } = useContext(LocalizationContext);

  const handleAccessSelect = useCallback(
    (e: RadioChangeEvent) => {
      const value: AccessSelect = e.target.value;
      setAccessSelect(value);

      // Reset values
      if (value === 'contact') {
        setFieldValue('contactId', initialValues.contactId);
        setFieldValue('groupCodes', []);
      } else {
        setFieldValue('groupCodes', initialValues.groupCodes);
        setFieldValue('contactId', undefined);
      }
    },
    [initialValues, setFieldValue],
  );

  const isPublicGallery = useMemo(
    () =>
      (gallery?.accessPolicy === GalleryAccessPolicy.PUBLIC ||
        gallery?.accessPolicy === GalleryAccessPolicy.OLD_PUBLIC) &&
      !gallery.isEmailRequired,
    [gallery],
  );

  return (
    <Form layout="vertical">
      <Form.Item label={t('app.common.name')} name="name" required hasFeedback={false}>
        <Input name="name" placeholder={t('app.common.name')} size="large" />
      </Form.Item>
      <Form.Item label={t('app.common.owner')} name="accessSelect" hasFeedback={false} style={{ marginBottom: 0 }}>
        {!isPublicGallery && (
          <Radio.Group
            name="accessSelect"
            value={accessSelect}
            optionType="button"
            buttonStyle="solid"
            size="large"
            style={{
              display: 'flex',
              width: '100%',
              textAlign: 'center',
              marginBottom: 8,
            }}
            onChange={handleAccessSelect}
            options={[
              {
                value: 'contact',
                label: t('app.common.contact'),
                style: { flex: 1 },
              },
              {
                value: 'group',
                label: t('app.gallery.access.groupTitle', { count: 2 }),
                style: { flex: 1 },
              },
            ]}
          />
        )}

        {accessSelect === 'contact' && (
          <SelectContact
            name="contactId"
            value={values.contactId ? [values.contactId] : []}
            loadedContacts={contact ? [contact] : []}
            label={isPublicGallery ? t('app.common.contact') : undefined}
          >
            {!!values.contactId && <Alert message={t('app.picklist.contact.info')} type="info" showIcon />}
          </SelectContact>
        )}

        {accessSelect === 'group' && (
          <SelectGroup
            galleryId={galleryId}
            name="groupCodes"
            value={values.groupCodes || []}
            loadedGroupCodes={groups || []}
            multiple
          />
        )}
      </Form.Item>

      <Form.Item label={t('app.common.description')} name="description" hasFeedback={false}>
        <Input.TextArea
          name="description"
          placeholder={t('app.common.description')}
          size="large"
          style={{
            height: 250,
          }}
        />
      </Form.Item>

      <Flex justify="flex-end">
        <Button htmlType="submit" type="primary" size="large" loading={isSubmitting} disabled={isSubmitting}>
          {t(values && 'id' in values && values.id ? 'app.common.edit' : 'app.common.add')}
        </Button>
      </Flex>
    </Form>
  );
};

export interface NewPicklistFormPayload {
  values: NewPicklistFormValues;
  formikBag: FormikBag<NewPicklistFormProps, NewPicklistFormValues>;
}

export interface NewPicklistFormProps extends PicklistFormProps {
  onSubmit: (payload: NewPicklistFormPayload) => void;
  defaultValues?: NewPicklistFormValues;
}

export interface EditPicklistFormPayload {
  values: EditPicklistFormValues;
  formikBag: FormikBag<EditPicklistFormProps, EditPicklistFormValues>;
}

export interface EditPicklistFormProps extends PicklistFormProps {
  onSubmit: (payload: EditPicklistFormPayload) => void;
  defaultValues: EditPicklistFormValues;
}

const picklistSchema: yup.SchemaOf<NewPicklistFormValues> = yup.object().shape(
  {
    name: yup.string().required(),
    description: yup.string(),
    contactId: yup.number().when('groupCodes', {
      is: (groupCodes: string[]) => !groupCodes?.length,
      then: yup.number().required(),
      otherwise: yup.number().notRequired(),
    }),
    groupCodes: yup.array().when('contactId', {
      is: (contactId: number) => !contactId,
      then: yup.array().min(1).required(),
      otherwise: yup.array().min(0),
    }),
  },
  [['contactId', 'groupCodes']],
);

export const NewPicklistForm = withFormik<NewPicklistFormProps, NewPicklistFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  validationSchema: picklistSchema,
  mapPropsToValues: ({ defaultValues }) => ({
    name: '',
    description: '',
    contactId: defaultValues?.contactId,
    groupCodes: [],
  }),
})(Picklist);

export const EditPicklistForm = withFormik<EditPicklistFormProps, EditPicklistFormValues>({
  handleSubmit: (values, formikBag) => {
    formikBag.props.onSubmit({ values, formikBag });
  },
  validationSchema: picklistSchema,
  mapPropsToValues: ({ defaultValues }) => ({
    id: defaultValues?.id,
    name: defaultValues?.name,
    description: defaultValues?.description,
    contactId: defaultValues?.contactId,
    groupCodes: defaultValues?.groupCodes,
  }),
})(Picklist);
