import { gql } from 'Operations/__generated__';

export const UPDATE_GALLERY_SPOTIFY_URI = gql(/* GraphQL */ `
  mutation UpdateGallerySpotifyUri($where: IdInput!, $data: GalleryUpdateSpotifyUriInputType!) {
    updateGallerySpotifyUri(where: $where, data: $data) {
      id
      spotifyUri
    }
  }
`);
