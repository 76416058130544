import React, { useCallback, useContext, useMemo } from 'react';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { App, Button, Divider, TablePaginationConfig } from 'antd';

import { compact } from 'lodash';

import Container from 'Components/Atoms/Container';
import Icon from 'Components/Atoms/Icon';
import Title from 'Components/Atoms/Title';

import LabeledSwitch from 'Components/Molecules/LabeledSwitch';
import AccessTable, { AccessCodeProps } from 'Components/Molecules/Tables/AccessTable';

import { AccessFormPayload, AccessType, EditAccessFormPayload } from 'Forms/Access';

import { Colors } from 'Themes';
import styled from 'Themes/Styled';

import { LocalizationContext } from 'i18n';

import { useModals } from 'Hooks/Modal';

import generateId from 'Helpers/GenerateId';

import {
  AccessCodeCharacteristic,
  AccessCodesWhereType,
  GalleryAccessPolicy,
  GetClientsQuery,
  GetGroupAccessCodesQuery,
  OrderType,
} from 'Operations/__generated__/graphql';

import { GET_GROUP_ACCESS_CODES } from 'Operations/Queries/AccessCode/GetGroupAccessCodes';
import { GET_CLIENTS } from 'Operations/Queries/Contact/GetClients';
import { GET_VISITORS } from 'Operations/Queries/Contact/GetVisitors';
import { GET_VISITORS_EMAIL } from 'Operations/Queries/Contact/GetVisitorsEmail';

import { CREATE_ACCESS_CODE } from 'Operations/Mutations/Gallery/CreateAccessCode';
import { DELETE_ACCESS_CODE } from 'Operations/Mutations/Gallery/DeleteAccessCode';
import { UPDATE_ACCESS_CODE } from 'Operations/Mutations/Gallery/UpdateAccessCode';
import { UPDATE_GALLERY } from 'Operations/Mutations/Gallery/UpdateGallery';

interface Props {
  isClientCodesAdminEnabled: boolean;
  type: AccessType;
  galleryId: number;
  galleryUrl: string;
  galleryContactId: number | undefined;
  galleryAccessPolicy: GalleryAccessPolicy;
}

const SpaceTitle = styled(Title)`
  flex: 1;
  font-size: 18px;
`;

const StyledDiv = styled.div`
  width: 100%;
`;

const PER_PAGE = 10;

const AccessItem = ({
  isClientCodesAdminEnabled,
  type,
  galleryId,
  galleryContactId,
  galleryAccessPolicy,
  galleryUrl,
}: Props) => {
  const { t } = useContext(LocalizationContext);
  const { openModal, closeModal } = useModals();
  const { message } = App.useApp();

  const whereParams: AccessCodesWhereType = useMemo(
    () => ({
      galleryId,
    }),
    [galleryId],
  );

  const {
    data: clientAccessCodesData,
    loading: isClientAccessLoading,
    fetchMore: fetchMoreClients,
  } = useQuery(GET_CLIENTS, {
    skip: type !== 'client',
    variables: {
      where: whereParams,
      paginate: {
        page: 1,
        perPage: PER_PAGE,
        order: OrderType.ASC,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const {
    data: groupAccessCodesData,
    loading: isGroupAccessLoading,
    fetchMore: fetchMoreGroups,
  } = useQuery(GET_GROUP_ACCESS_CODES, {
    skip: type !== 'group',
    variables: {
      where: whereParams,
      paginate: {
        page: 1,
        perPage: PER_PAGE,
        order: OrderType.ASC,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const {
    data: visitorAccessCodesData,
    loading: isVisitorAccessLoading,
    fetchMore: fetchMoreVisitors,
  } = useQuery(GET_VISITORS, {
    skip: type !== 'visitor',
    variables: {
      where: whereParams,
      paginate: {
        page: 1,
        perPage: PER_PAGE,
        order: OrderType.ASC,
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    returnPartialData: true,
  });

  const [getVisitorsEmail, { loading: isVisitorsEmailAccessLoading }] = useLazyQuery(GET_VISITORS_EMAIL, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    onCompleted(data) {
      if (data.getVisitorsEmail.edges.length > 0) {
        const visitorsEmail = data.getVisitorsEmail.edges
          .map(visitor => {
            return visitor.email;
          })
          .filter(name => !!name);

        navigator.clipboard.writeText(visitorsEmail.join(','));

        message.success(t('app.message.gallery.visitors.email.clipboard.success'));
      }
    },
  });

  const data: AccessCodeProps[] = useMemo(() => {
    if (type === 'client' || type === 'visitor') {
      const dataToParse = type === 'client' ? clientAccessCodesData?.getClients : visitorAccessCodesData?.getVisitors;

      return (
        compact(
          dataToParse?.edges.map(({ displayName, email, accessCode }) => {
            if (!accessCode) {
              return null;
            }

            return {
              code: accessCode?.code,
              isDefault: accessCode?.isDefault,
              groupName: accessCode?.name,
              contactId: accessCode?.contact?.id,
              contact: { displayName, email },
              stats: {
                ordersAmount: accessCode?.stats?.ordersAmount,
                ordersCount: accessCode?.stats?.ordersCount,
              },
            };
          }),
        ) || []
      );
    }

    return (
      groupAccessCodesData?.getGroupAccessCodes?.edges.map(({ code, name, contact, isDefault, stats }) => ({
        code,
        isDefault,
        groupName: name,
        contactId: contact?.id,
        contact: { displayName: contact?.displayName },
        stats: {
          ordersAmount: stats?.ordersAmount,
          ordersCount: stats?.ordersCount,
        },
      })) || []
    );
  }, [type, clientAccessCodesData, groupAccessCodesData, visitorAccessCodesData]);

  const accessCodesTotal = useMemo(() => {
    if (type === 'client') {
      return clientAccessCodesData?.getClients?._count || 0;
    }

    if (type === 'visitor') {
      return visitorAccessCodesData?.getVisitors?._count || 0;
    }

    return groupAccessCodesData?.getGroupAccessCodes?._count || 0;
  }, [clientAccessCodesData, visitorAccessCodesData, groupAccessCodesData]);

  const [updateAccessCode] = useMutation(UPDATE_ACCESS_CODE, {
    refetchQueries: [
      type === 'group'
        ? { query: GET_GROUP_ACCESS_CODES, variables: { where: whereParams } }
        : { query: GET_CLIENTS, variables: { where: whereParams } },
    ],
    awaitRefetchQueries: true,
  });

  const [deleteAccessCode] = useMutation(DELETE_ACCESS_CODE, {
    onError: () => {
      message.error(t('app.message.error.somethingWentWrong'));
    },
    onCompleted: () => {
      closeModal();
      message.success(t('app.access.delete.success'));
    },
  });

  const [updateIsClientCodesAdminEnabled] = useMutation(UPDATE_GALLERY, {
    variables: {
      where: {
        id: galleryId,
      },
      data: {
        isClientCodesAdminEnabled: !isClientCodesAdminEnabled,
      },
    },
  });

  const [createAccessCode] = useMutation(CREATE_ACCESS_CODE);

  const handleAddGroupOnSubmit = useCallback(
    async ({ values: { name, code }, formikBag }: AccessFormPayload) => {
      try {
        if (!name) {
          throw new Error('Name is required');
        }

        await createAccessCode({
          variables: {
            where: { id: galleryId },
            data: { code, name },
          },
          update(cache, { data }) {
            const query = cache.readQuery({
              query: GET_GROUP_ACCESS_CODES,
              variables: { where: whereParams },
            });

            if (query && data) {
              return cache.writeQuery({
                query: GET_GROUP_ACCESS_CODES,
                variables: { where: whereParams },
                data: {
                  getGroupAccessCodes: {
                    ...query.getGroupAccessCodes,
                    _count: query.getGroupAccessCodes._count + 1,
                    edges: [...query.getGroupAccessCodes.edges, { ...data.createAccessCode }],
                  },
                },
              });
            }
          },
        });

        message.success(t('app.message.gallery.create.success'));
        formikBag.setSubmitting(false);
        closeModal();
      } catch (error) {
        message.error(t(`app.message.error.somethingWentWrong`));
        formikBag.setSubmitting(false);
      }
    },
    [createAccessCode, galleryId, t, closeModal, whereParams],
  );

  const handleEditOnSubmit = useCallback(
    async ({ values: { name, code, oldCode }, formikBag }: EditAccessFormPayload) => {
      try {
        await updateAccessCode({
          variables: {
            where: { code: oldCode, galleryId },
            data: { code, name: name ?? '' },
          },
          update(cache, { data }) {
            // We only need to manually update the cache if the key field (code) has changed
            if (code && code !== oldCode && data) {
              if (type === 'client') {
                // Replace the old code reference by the new one
                const query = cache.readQuery({
                  query: GET_CLIENTS,
                  variables: { where: whereParams },
                });

                if (query) {
                  // Remove old one
                  cache.evict({
                    id: cache.identify({
                      code: oldCode,
                      galleryId,
                      __typename: 'AccessCode',
                    }),
                  });
                  cache.gc();

                  // Update query with the new code using the data from the getClients query
                  cache.writeQuery({
                    query: GET_CLIENTS,
                    variables: { where: whereParams },
                    data: {
                      getClients: {
                        ...query.getClients,
                        edges: query.getClients.edges.map(client =>
                          client.accessCode?.code === oldCode
                            ? { ...client, accessCode: data.updateAccessCode }
                            : client,
                        ),
                      },
                    },
                  });
                }
              }

              if (type === 'group') {
                // Replace the old code reference by the new one
                const query = cache.readQuery({
                  query: GET_GROUP_ACCESS_CODES,
                  variables: { where: whereParams },
                });

                if (query) {
                  // Update query with the new code using the data from the getGroupAccessCodes query
                  cache.writeQuery({
                    query: GET_GROUP_ACCESS_CODES,
                    variables: { where: whereParams },
                    data: {
                      getGroupAccessCodes: {
                        ...query.getGroupAccessCodes,
                        edges: query.getGroupAccessCodes.edges.map(access =>
                          access.code === oldCode ? { ...access, code } : access,
                        ),
                      },
                    },
                  });
                }
              }
            }
          },
        });

        message.success(t('app.message.gallery.update.success'));
        formikBag.setSubmitting(false);
        closeModal();
      } catch (error) {
        console.log(error);
        message.error(t(`app.message.error.somethingWentWrong`));
        formikBag.setSubmitting(false);
      }
    },
    [updateAccessCode, galleryId, t, closeModal, whereParams],
  );

  const handleDeleteCode = useCallback(
    async (code: string) => {
      await deleteAccessCode({
        variables: {
          where: {
            code,
            galleryId,
          },
        },
        update(cache) {
          cache.evict({
            id: cache.identify({
              code,
              galleryId,
              __typename: 'AccessCode',
            }),
          });
          cache.gc();

          const query = cache.readQuery({
            query: GET_GROUP_ACCESS_CODES,
            variables: { where: whereParams },
          });

          if (query) {
            // Update query with the new code using the data from the getGroupAccessCodes query
            cache.writeQuery({
              query: GET_GROUP_ACCESS_CODES,
              variables: { where: whereParams },
              data: {
                getGroupAccessCodes: {
                  ...query.getGroupAccessCodes,
                  _count: query.getGroupAccessCodes._count - 1,
                },
              },
            });
          }
        },
      });
    },
    [deleteAccessCode, galleryId, whereParams],
  );

  const handleOpenAddModal = useCallback(() => {
    openModal('ACCESS_CODE', {
      name: 'new',
      onSubmit: handleAddGroupOnSubmit,
      defaultValues: {
        code: generateId({ size: 6 }),
        accessType: type,
        name: '',
      },
      galleryAccessPolicy,
    });
  }, [galleryAccessPolicy, handleAddGroupOnSubmit, openModal, type]);

  const handleFetchMore = useCallback(
    ({ current }: TablePaginationConfig) => {
      if (type === 'client') {
        fetchMoreClients({
          variables: {
            paginate: {
              page: current || 1,
              perPage: PER_PAGE,
              order: OrderType.ASC,
            },
          },
        });
      }

      if (type === 'group') {
        fetchMoreGroups({
          variables: {
            paginate: {
              page: current || 1,
              perPage: PER_PAGE,
              order: OrderType.ASC,
            },
          },
        });
      }

      if (type === 'visitor') {
        fetchMoreVisitors({
          variables: {
            paginate: {
              page: current || 1,
              perPage: PER_PAGE,
              order: OrderType.ASC,
            },
          },
        });
      }
    },
    [fetchMoreClients, fetchMoreGroups, fetchMoreVisitors],
  );

  const handleCopyEmails = useCallback(() => {
    if (accessCodesTotal > 0) {
      getVisitorsEmail({
        variables: {
          where: { galleryId },
          paginate: {
            page: 1,
            perPage: accessCodesTotal,
            order: OrderType.ASC,
          },
        },
      });
    }
  }, [accessCodesTotal]);

  return (
    <Container direction="column" block>
      <Container padding="smallMargin" justify="space-between" gap={4} align="center" block flex={1}>
        <Container gap={4} align="center">
          <Icon name="admin" size={28} color={Colors.black} />
          <SpaceTitle level="h5">
            {type === 'group' && t('app.gallery.access.groupTitle', { count: 2 })}
            {type === 'client' && t('app.gallery.access.customerTitle')}
            {type === 'visitor' && t('app.gallery.access.visitorTitle')}
          </SpaceTitle>
        </Container>
        {type === 'group' && (
          <Container justify="flex-end" gap={4} align="center" flex={1}>
            <LabeledSwitch
              checked={isClientCodesAdminEnabled}
              label={t('app.access.update.isClientCodesAdminEnabled')}
              onCheckChanged={() => updateIsClientCodesAdminEnabled()}
            />
            <Divider type="vertical" />
            <Button key="add" type="primary" size="large" onClick={handleOpenAddModal}>
              {t('app.common.addGroup')}
            </Button>
          </Container>
        )}
        {type === 'visitor' && (
          <Container justify="flex-end" gap={4} align="center" flex={1}>
            <Button key="add" size="small" onClick={handleCopyEmails} loading={isVisitorsEmailAccessLoading}>
              {t('app.common.copyEmails')}
            </Button>
          </Container>
        )}
      </Container>
      <Container block padding="smallMargin" flex={1}>
        <StyledDiv>
          <AccessTable
            data={data}
            type={type}
            galleryId={galleryId}
            isLoading={isClientAccessLoading || isGroupAccessLoading || isVisitorAccessLoading}
            galleryUrl={galleryUrl}
            galleryContactId={galleryContactId}
            galleryAccessPolicy={galleryAccessPolicy}
            total={accessCodesTotal}
            paginationSize={PER_PAGE}
            onChange={handleFetchMore}
            onEdit={type === 'client' || type === 'group' ? handleEditOnSubmit : undefined}
            onDelete={handleDeleteCode}
          />
        </StyledDiv>
      </Container>
    </Container>
  );
};

export default AccessItem;
