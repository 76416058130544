import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from '@apollo/client';
import { Flex } from 'antd';

import { useFormikContext } from 'formik';
import { Form } from 'formik-antd';
import { uniqBy } from 'lodash';

import SelectCard from 'Components/Molecules/Cards/SelectCard';
import Select from 'Components/Molecules/Form/Select';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { GalleryWorkmode, GetCatalogsQuery } from 'Operations/__generated__/graphql';

import { GET_CATALOG } from 'Operations/Queries/Catalog/GetCatalog';
import { GET_CATALOGS } from 'Operations/Queries/Catalog/GetCatalogs';

import { DataQueryParams, EDGES_PARAMS, GalleryFormValues } from './types';

const GalleryProductsStep = () => {
  const { t } = useContext(LocalizationContext);
  const { setValues, values, initialValues } = useFormikContext<GalleryFormValues>();
  const [catalogsQueryParams, setCatalogsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);

  const {
    data: catalogsData,
    loading: isCatalogsLoading,
    fetchMore: fetchMoreCatalogs,
  } = useQuery(GET_CATALOGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: catalogsQueryParams,
    },
  });

  // Get the default catalog data (useful if not in the pagination of the catalogs first page)
  useQuery(GET_CATALOG, {
    skip: !initialValues.catalogId,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        id: initialValues.catalogId as number,
      },
    },
  });

  const catalogs: {
    _count: number;
    edges: GetCatalogsQuery['getCatalogs']['edges'];
  } = useMemo(() => {
    if (!catalogsData?.getCatalogs) {
      return { _count: 0, edges: [] };
    }

    return {
      edges: uniqBy([...catalogsData.getCatalogs.edges], 'id'),
      _count: catalogsData.getCatalogs._count,
    };
  }, [catalogsData?.getCatalogs]);

  const handleLoadMoreCatalogs = useCallback(async () => {
    const params = { ...catalogsQueryParams, page: catalogsQueryParams.page + 1 };

    await fetchMoreCatalogs({
      variables: {
        where: params,
      },
    });

    setCatalogsQueryParams(params);
  }, [catalogsQueryParams, fetchMoreCatalogs]);

  // Set the default catalog
  useEffect(() => {
    if (values.catalogId === undefined) {
      let defaultCatalog = catalogs.edges.find(catalog => catalog.isDefault);

      if (!defaultCatalog) {
        defaultCatalog = catalogs.edges[0];
      }

      if (defaultCatalog) {
        setValues(oldValues => ({
          ...oldValues,
          catalogId: defaultCatalog?.id,
          catalogName: defaultCatalog?.name,
        }));
      }
    } else {
      setValues(oldValues => ({
        ...oldValues,
        catalogName: catalogs.edges.find(catalog => catalog.id === values.catalogId)?.name,
      }));
    }
  }, [catalogs.edges, setValues, values.catalogId]);

  return (
    <Flex vertical gap="large">
      <SelectCard
        icon={Images.cart}
        title={t('app.gallery.catalog.enable.title')}
        isSelected={!!values.catalogId}
        align="flex-start"
        pointer={false}
      >
        <Form.Item label={t('app.common.catalog', { count: 1 })} name="catalogId" hasFeedback={false}>
          <Flex vertical gap="small">
            <Select
              name="catalogId"
              size="large"
              loadMore={handleLoadMoreCatalogs}
              hasFetchAll={catalogs._count === catalogs.edges.length}
              loading={isCatalogsLoading}
            >
              {catalogs.edges.map(catalog => (
                <Select.Option key={catalog.id} value={catalog.id} title={''}>
                  {catalog.name}
                </Select.Option>
              ))}
            </Select>
          </Flex>
        </Form.Item>
      </SelectCard>
      {values.workmode !== GalleryWorkmode.RETOUCH_FIRST && (
        <SelectCard
          icon={Images.eye}
          title={t('app.gallery.catalog.disable.title')}
          isSelected={!values.catalogId}
          onClick={() =>
            setValues(oldValues => ({
              ...oldValues,
              catalogId: null,
              catalogName: null,
            }))
          }
        >
          <p>{t('app.gallery.catalog.disable.description')}</p>
        </SelectCard>
      )}
    </Flex>
  );
};

export default GalleryProductsStep;
