import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useLazyQuery, useQuery } from '@apollo/client';
import { Flex } from 'antd';

import { useFormikContext } from 'formik';
import { Form } from 'formik-antd';
import { uniqBy } from 'lodash';

import SelectCard from 'Components/Molecules/Cards/SelectCard';
import Select from 'Components/Molecules/Form/Select';

import { Images } from 'Themes';

import { LocalizationContext } from 'i18n';

import { GetGalleryPresetsQuery } from 'Operations/__generated__/graphql';

import { GET_GALLERY_PRESET } from 'Operations/Queries/GalleryPreset/GetGalleryPreset';
import { GET_GALLERY_PRESETS } from 'Operations/Queries/GalleryPreset/GetGalleryPresets';

import { DataQueryParams, EDGES_PARAMS, GalleryFormValues } from './types';

const GalleryPresetStep = () => {
  const { setValues, values, initialValues } = useFormikContext<GalleryFormValues>();
  const { t } = useContext(LocalizationContext);

  const [presetsQueryParams, setPresetsQueryParams] = useState<DataQueryParams>(EDGES_PARAMS);
  const [getPreset, { data: galleryPresetData }] = useLazyQuery(GET_GALLERY_PRESET);

  const {
    data: presetsData,
    loading: isPresetsLoading,
    fetchMore: fetchMorePresets,
  } = useQuery(GET_GALLERY_PRESETS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    variables: {
      where: presetsQueryParams,
    },
  });

  const handleLoadMoreGalleryPresets = useCallback(async () => {
    const params = { ...presetsQueryParams, page: presetsQueryParams.page + 1 };

    await fetchMorePresets({
      variables: {
        where: params,
      },
    });

    setPresetsQueryParams(params);
  }, [fetchMorePresets, presetsQueryParams]);

  const galleryPresets: {
    _count: number;
    edges: GetGalleryPresetsQuery['getGalleryPresets']['edges'];
  } = useMemo(() => {
    if (!presetsData?.getGalleryPresets) {
      return { _count: 0, edges: [] };
    }

    return {
      edges: uniqBy([...presetsData.getGalleryPresets.edges], 'id'),
      _count: presetsData.getGalleryPresets._count,
    };
  }, [presetsData]);

  // Get the preset data if the galleryPresetId is set
  useEffect(() => {
    const presetId = initialValues.galleryPresetId || values.galleryPresetId;
    if (presetId) {
      getPreset({
        variables: {
          where: {
            id: presetId,
          },
        },
      });
    }
  }, [getPreset, initialValues.galleryPresetId, values.galleryPresetId]);

  // Set the values from the preset
  useEffect(() => {
    if (galleryPresetData?.getGalleryPreset.id) {
      const {
        getGalleryPreset: { accessPolicy, catalog, locale, workmode, name, isEmailRequired },
      } = galleryPresetData;

      setValues(oldValues => ({
        ...oldValues,
        accessPolicy,
        catalogId: catalog?.id,
        catalogName: catalog?.name,
        locale,
        workmode,
        galleryPresetName: name,
        isEmailRequired,
      }));
    }
  }, [galleryPresetData, setValues]);

  return (
    <Flex vertical gap="large">
      <SelectCard
        title={t('app.gallery.preset.title')}
        icon={Images.galleryModel}
        isSelected={!!values.galleryPresetId}
        align="flex-start"
        pointer={false}
      >
        <p>{t('app.gallery.preset.description')}</p>
        <Form.Item label={t('app.gallery.preset.label')} name="galleryPresetId" hasFeedback={false}>
          <Select
            name="galleryPresetId"
            size="large"
            loadMore={handleLoadMoreGalleryPresets}
            hasFetchAll={galleryPresets._count === galleryPresets.edges.length}
            loading={isPresetsLoading}
          >
            {galleryPresets.edges.map(preset => (
              <Select.Option key={preset.id} value={preset.id} title={''}>
                {preset.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </SelectCard>
      <SelectCard
        title={t('app.gallery.noPreset.title')}
        icon={Images.configuration}
        isSelected={!values.galleryPresetId}
        onClick={() =>
          setValues(oldValues => ({
            ...oldValues,
            galleryPresetId: null,
            galleryPresetName: null,
          }))
        }
      >
        <p>{t('app.gallery.noPreset.description')}</p>
      </SelectCard>
    </Flex>
  );
};

export default GalleryPresetStep;
